/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import { IMAGES } from '../../constant/theme';
import AccountForm from './AccountForm';
import { showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import { AdminAccountModel } from '../../models/admin-info-model';
import UsersService from '../../../services/UsersService';
import { QueryParamsKey, UserStatus } from '../../constant/AppConstant';
import { TextApp, TextKey } from '../../../assets/text-app';

const EditUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false);
    // Form
    const [adminAccount, setAdminAccount] = useState();
    const [vendors, setVendors] = useState([]);
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [apiKeyDisplay, setApiKeyDisplay] = useState('');
    const [showApiKey, setShowApiKey] = useState(false);

    const onUpdateAdminAccount = (index, data) => {
        setAdminAccount(data);
    };

    // Load all vendors available
    const findVendors = async () => {
        try {
            const allVendors = await VendorsService.findVendors({}, false, true);
            const vendorsAvailable = allVendors && allVendors.length > 0 ? allVendors.filter((v) => { return v.allowCreateAccount && v.status === UserStatus.ACTIVE }) : [];

            setVendors(vendorsAvailable);
        } catch (error) {
            console.warn(error);
        }
    };

    const loadAccountDetails = async () => {
        const params = new URLSearchParams(location.search)
        const userId = params && params.get(QueryParamsKey.ID) ? params.get(QueryParamsKey.ID) : '';

        try {
            setLoading(true);

            await UsersService.getUserDetails(userId)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        setAdminAccount(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const getApiKeyDisplay = () => {
        if (showApiKey) {
            if (adminAccount && adminAccount.apiAccess) {
                return adminAccount.apiKey ? adminAccount.apiKey : '';
            } else {
                return TextApp.getText(TextKey.character_security_hint);
            }
        } else {
            return TextApp.getText(TextKey.character_security_hint);
        }
    }

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Get all vendors available.
        findVendors();
        // Get account details
        loadAccountDetails();
    }, [navigate, location]);

    /**
     * Handle generate new api key
     * @param {*} e 
     * @returns 
     */
    const handleGenerateNewApiKey = async (e) => {
        e.preventDefault();

        if (adminAccount == null || adminAccount.id === '') {
            showToastError(TextApp.getText(TextKey.data_invalid));
            return;
        }

        try {
            setLoading(true);

            await UsersService.generateApiKey(adminAccount.id)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response && response.message) {
                            showToastSuccess(response.message);
                        }
                        adminAccount.apiKey = response.data;
                        setRefreshStatus(!refreshStatus);
                        setShowApiKey(true);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        if (adminAccount.id == null || adminAccount.id === '') {
            showToastError(TextApp.getText(TextKey.data_invalid));
            return;
        }

        // Send data to create new account
        requestUpdateAccountInfo(JSON.stringify(adminAccount));
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        let errorAdminAcc = new AdminAccountModel();
        let validateAdminAcc = AdminAccountModel.validate(adminAccount, errorAdminAcc, adminAccount.password != null && adminAccount.password !== '' ? false : true);

        return validateAdminAcc;
    }

    /**
    * Request update account info
    * @param {} data 
    */
    const requestUpdateAccountInfo = async (data) => {
        try {
            setLoading(true);

            // Create new account
            await UsersService.updateAccount(adminAccount.id, data)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.account_updated_successfully));
                        resetForm();
                        // navigate(-1);
                        navigate('/accounts', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        setAdminAccount();
    };

    return (
        <>
            <MainPagetitle mainTitle="Admin Management" pageTitle="Profile" parentTitle="Home" />
            <div className="container-fluid">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Edit Account</h4>
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={9}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                {adminAccount && <><AccountForm key={adminAccount.id} index={0} onUpdate={onUpdateAdminAccount} data={adminAccount} vendors={vendors} /></>}
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                    <Col xl={3}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                <span>
                                                                    <input type="checkbox" id="allow-api-access" className="me-2" value={adminAccount && adminAccount.apiAccess ? adminAccount.apiAccess : false} checked={adminAccount && adminAccount.apiAccess ? adminAccount.apiAccess : false} onChange={() => {
                                                                        adminAccount.apiAccess = !adminAccount.apiAccess;
                                                                        setShowApiKey(false);
                                                                        setRefreshStatus(!refreshStatus);
                                                                    }} />
                                                                    <label htmlFor="allow-api-access" className='text-info'>{TextApp.getText(TextKey.allow_api_access_label)}</label>
                                                                </span>
                                                                <br /><button className="btn btn-secondary light btn-sm mt-2" onClick={(e) => { handleGenerateNewApiKey(e) }} disabled={adminAccount == null || !adminAccount.apiAccess}>{TextApp.getText(TextKey.generate_new_api_key_label)}</button>
                                                                <input type="text" className="form-control" value={getApiKeyDisplay()} placeholder={TextApp.getText(TextKey.generate_new_api_key_label)} disabled />
                                                                {adminAccount && adminAccount.apiAccess && <><span className='fs-12' onClick={() => { setShowApiKey(!showApiKey) }}>
                                                                    <i>{TextApp.getText(TextKey.auth_basic_type_hint)}</i>
                                                                    <br /><i>{TextApp.getText(TextKey.tap_to_display_api_key_label)}</i>
                                                                </span></>}
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Update Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            </div >
        </>
    )
}

export default EditUser;