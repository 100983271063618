// import { CustomerModel } from '../jsx/models/customer.model';
import { ApiMethod, SpecialVendors } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod } from './ApiService';
const vsprintf = require('sprintf-js').vsprintf;

export default class CustomersService {
    /**
     * Find customers
     * @param {*} queryParams 
     * @returns {data: [], paging: {}}
     * "paging": {
        "page": number,
        "take": number,
        "total": number,
        "pageCount": number,
        "hasPreviousPage": boolean,
        "hasNextPage": boolean
    }
     */
    static async getCustomers(queryParams = {}) {
        const customersURL = `${ApiEndpoint}/${ApiMethod.Customers.ListCustomers}`;

        return Api.invokeApi(customersURL, HttpMethod.GET, { queryParams: queryParams, dataResponseOnly: false })
            .then((response) => {
                // let result = [];

                // if (response) {
                //     result = response.map(cus => CustomerModel.toModel(cus));
                // }

                // return result;
                return response;
            })
            .catch(err => {
                return null;
            });
    }

    /**
     * Create new customer
     * @param {*} customerInfo 
     * @returns 
     */
    static async createNewCustomer(customerInfo) {
        const newCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.NewCustomer}`;

        return Api.invokeApi(newCustomerURL, HttpMethod.POST, { payload: customerInfo, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update customer
     * @param cusId 
     * @param {*} customerInfo 
     * @returns
     */
    static async updateCustomer(cusId, customerInfo) {
        const updateCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.UpdateCustomer}/${cusId}`;

        return Api.invokeApi(updateCustomerURL, HttpMethod.PUT, { payload: customerInfo, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update customer's status
     * @param cusId 
     * @param status 
     * @returns
     */
    static async updateCustomerStatus(cusId, status) {
        const updateCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.UpdateStatus}/${cusId}`;
        const data = {
            status: status
        };

        return Api.invokeApi(updateCustomerURL, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }


    /**
    * Sync failing data
    * @returns
    */
    static async syncFaillingData() {
        const syncCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.SyncFailingData}`;

        return Api.invokeApi(syncCustomerURL, HttpMethod.POST, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Import vendor's customers
     * @param cusId 
     * @param {*} customerInfo 
     * @returns
     */
    static async importVendorCustomers(vendorId, data) {
        const importCustomersURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.ImportVendorCustomersFromFile, [vendorId])}`;

        return Api.invokeApi(importCustomersURL, HttpMethod.POST, { payload: data, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update customers from file
     * @param cusId 
     * @param {*} customerInfo 
     * @returns
     */
    static async updateCustomersFromFile(vendorId, data) {
        const importCustomersURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.UpdateCustomersFromFile, [vendorId])}`;

        return Api.invokeApi(importCustomersURL, HttpMethod.POST, { payload: data, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Verified unified customer
     * @param cusId 
     * @param verificationStatus
     * @returns
     */
    static async verifiedCustomer(cusId, verificationStatus) {
        const updateCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.Verified}/${cusId}`;
        const data = {
            verification: verificationStatus
        };

        return Api.invokeApi(updateCustomerURL, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
    * Reject all changes from customer's information
    * @param cusId 
    * @param message
    * @returns
    */
    static async rejectAllChangesCustomerInfo(cusId, message) {
        const updateCustomerURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.RejectChangesCustomerInfo, [cusId])}`;
        const data = {
            note: message
        }

        return Api.invokeApi(updateCustomerURL, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Get unified customer details
     * @param customerId 
     * @returns 
     */
    static async getUnifiedCustomerDetails(customerId) {
        const detailsCustomerURL = `${ApiEndpoint}/${ApiMethod.Customers.Details}/${customerId}`;

        return Api.invokeApi(detailsCustomerURL, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Get unified customer details by contact no
     * @param contactNo
     * @returns 
     */
    static async getUnifiedCustomerDetailsByContact(contactNo) {
        const detailsCustomerURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.DetailsByContact, [contactNo])}`;

        return Api.invokeApi(detailsCustomerURL, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
    * Request add VietPOM identity
    * @param customerId 
    * @returns 
    */
    static async addVietPOMIdentity(customerId) {
        const addIdentityURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.AddVietPOMIdentity, [customerId])}`;

        return Api.invokeApi(addIdentityURL, HttpMethod.PUT, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Request remove VietPOM identity
     * @param customerId 
     * @param pomId
     * @returns 
     */
    static async removeVietPOMIdentity(customerId, pomId) {
        const removeIdentityURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.RemoveVietPOMIdentity, [customerId, pomId])}`;

        return Api.invokeApi(removeIdentityURL, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
    * Delete customer by id
    * @param {*} id 
    * @returns 
    */
    static async deteteById(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Customers.DeleteById}/${id}`;

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Find customer code from vendor
     * @param vendor 
     * @param keysearch 
     * @returns 
     */
    static async findCustomerCodeFromVendor(vendor, { customerCode = '', phoneNumber = '' }) {
        if (vendor == null || (customerCode === '' && phoneNumber === ''))
            return null;

        if (vendor.vendorIdentityCallbackEndpoint == null || vendor.vendorIdentityCallbackEndpoint === '') {
            return null;
        }

        let result = null;
        const customerURL = `${ApiEndpoint}/${vsprintf(ApiMethod.Customers.FindCustomerFromVendor, [vendor.vendorId, customerCode, phoneNumber])}`;

        switch (vendor.vendorId) {
            case SpecialVendors.HOANG_DUC:
                return Api.invokeApi(customerURL, HttpMethod.GET, { dataResponseOnly: false })
                    .then((response) => {
                        if (response && response.data && response.data.customerCode) {
                            return response.data.customerCode;
                        } else {
                            return null;
                        }
                    });

            // if (vendor.vendorIdentityCallbackEndpoint == null || vendor.vendorIdentityCallbackEndpoint === '')
            //     return null;

            // const authURL = vendor.vendorAuthEndpoint;
            // const credential = vendor.vendorCredential;
            // const credentialData = new URLSearchParams();
            // const keys = credential != null ? Object.keys(credential) : [];

            // for (let i = 0; i < keys.length; i++) {
            //     credentialData.append(keys[i], credential[keys[i]]);
            // }

            // result = await Api.invokeApi(authURL, HttpMethod.POST, {
            //     payload: credentialData, headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     },
            //     dataResponseOnly: false
            // })
            //     .then((resAuth) => {
            //         if (resAuth && resAuth.access_token) {
            //             const getCustomerURL = `${vendor.vendorIdentityCallbackEndpoint}?Code=&Email=&SDT=${keysearch}`

            //             return Api.invokeApi(getCustomerURL, HttpMethod.GET, { headers: Api.makeAuthorizationHeader(resAuth.access_token), dataResponseOnly: false })
            //         } else {
            //             return null;
            //         }
            //     })
            //     .then((resCus) => {
            //         if (resCus && resCus.detail) {
            //             return resCus.detail.customerCode;
            //         } else {
            //             return null;
            //         }
            //     })
            //     .catch(err => {
            //         console.log(`err: ${JSON.stringify(err)}`);
            //         return null;
            //     });
            // break;

            default:
                break;
        }

        return result;
    }

    /**
     * Get sub-users
     * @param {*} customerId 
     * @returns 
     */
    static async getSubUsers(customerId) {
        const customersURL = vsprintf(`${ApiEndpoint}/${ApiMethod.Customers.ListSubUsers}`, [customerId]);

        return Api.invokeApi(customersURL, HttpMethod.GET, {})
            .then((response) => {
                return response;
            })
            .catch(err => {
                return null;
            });
    }

    /**
     * Create new sub-user
     * @param {*} data 
     * @returns 
     */
    static async createSubUser(cusId, data) {
        const newSubUserURL = vsprintf(`${ApiEndpoint}/${ApiMethod.Customers.CreateSubUser}`, [cusId]);

        return Api.invokeApi(newSubUserURL, HttpMethod.POST, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update sub-user
     * @param cusId 
     * @param subUserId 
     * @param {*} data 
     * @returns
     */
    static async updateSubUser(cusId, subUserId, data) {
        const updateSubUserURL = vsprintf(`${ApiEndpoint}/${ApiMethod.Customers.UpdateSubUser}`, [cusId, subUserId]);

        return Api.invokeApi(updateSubUserURL, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
    * Delete sub-user
    * @param {*} cusId
    * @param {*} id 
    * @returns 
    */
    static async deteteSubUserById(cusId, id) {
        const url = vsprintf(`${ApiEndpoint}/${ApiMethod.Customers.DeleteSubUser}`, [cusId, id]);

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }
}
