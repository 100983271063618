import { getLocalData, parseFilterToString } from '../helper/helper';
import { ApiMethod, QueryParamsKey } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod, LOCAL_STORAGE_KEY } from './ApiService';
const vsprintf = require('sprintf-js').vsprintf;

export default class ReportService {
    /**
    * Get report summary on dashboard
    * @param {*} 
    * @returns 
    */
    static getDashboardSummary() {
        const url = `${ApiEndpoint}/${ApiMethod.Report.GetDashboardSummary}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    static getHistoryImportCustomers(query = null) {
        const url = `${ApiEndpoint}/${ApiMethod.Report.GetHistoryImportCustomers}`;

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: query })
            .then((response) => {
                return response;
            });
    }

    static getTotalRecordsImportFailed(historyId) {
        const url = `${ApiEndpoint}/${ApiMethod.Report.CountCustomersImportFailed}/${historyId}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    static getTotalChunkFilesExport(url) {
        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Export records failed to file
     * @returns 
     */
    static exportRecordsFailedToFile(historyId) {
        const url = `${ApiEndpoint}/${ApiMethod.Report.ExportCustomersImportFailed}/${historyId}`;
        return url;
    }

    /**
     * Get template file vendor's customers
     * @returns 
     */
    static getURLTemplateFileVendorCustomers() {
        const url = `${ApiEndpoint}/${ApiMethod.Report.TemplateFileVendorCustomers}`;
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = url;

        if (accessToken) {
            requestURL = `${url}?${QueryParamsKey.ACCESS_TOKEN}=${accessToken}`;
        }

        return requestURL;
    }

    /**
     * Get template file vendor's customers (update customers)
     * @returns 
     */
    static getURLTemplateFileUpdateVendorCustomers() {
        const url = `${ApiEndpoint}/${ApiMethod.Report.TemplateFileUpdateVendorCustomers}`;
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = url;

        if (accessToken) {
            requestURL = `${url}?${QueryParamsKey.ACCESS_TOKEN}=${accessToken}`;
        }

        return requestURL;
    }

    /**
    * Export unified customers to file
    * @returns 
    */
    static exportCustomersToFile(queryParams = {}) {
        const url = `${ApiEndpoint}/${ApiMethod.Report.ExportUnifiedCustomersToFile}`;
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = url;

        if (accessToken) {
            queryParams[QueryParamsKey.ACCESS_TOKEN] = accessToken;
        }

        const queryParamsStr = parseFilterToString(queryParams);

        if (queryParamsStr != null && queryParamsStr !== "") {
            requestURL = `${url}?${queryParamsStr}`;
        }

        return requestURL;
    }
}
