import { getLocalData } from '../helper/helper';
import { ApiMethod, QueryParamsKey } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod, LOCAL_STORAGE_KEY } from './ApiService';

export default class AssetsService {
    /**
     * Get file URL
     * @param {*} keyFile 
     * @returns 
     */
    static getFileURL(keyFile) {
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = `${ApiEndpoint}/${ApiMethod.Assets.GetFile}${keyFile}`;

        if (accessToken) {
            requestURL = `${requestURL}&${QueryParamsKey.ACCESS_TOKEN}=${accessToken}`;
        }

        return requestURL;
    }

    static getDocumentFileURL(keyFile) {
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = `${ApiEndpoint}/${ApiMethod.Assets.GetDocumentFile}${keyFile}`;

        if (accessToken) {
            requestURL = `${requestURL}&${QueryParamsKey.ACCESS_TOKEN}=${accessToken}`;
        }

        return requestURL;
    }

    /**
     * Get signed url
     * @param {*} keyFile 
     * @returns 
     */
    static async getSignedURL(url, fullData = false) {
        // const url = `${ApiEndpoint}/${ApiMethod.Assets.GetFile}${keyFile}`;
        const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
        const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;
        const accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        let requestURL = url;

        if (accessToken) {
            requestURL = `${url}&${QueryParamsKey.ACCESS_TOKEN}=${accessToken}`;
        }

        return Api.invokeApi(requestURL, HttpMethod.GET, {})
            .then((response) => {
                if (response) {
                    if (fullData) {
                        return response;
                    } else {
                        return response.url;
                    }
                } else {
                    return '';
                }
            }).catch(err => {
                return '';
            });
    }
}
