/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Dropdown } from 'react-bootstrap';
import {
    ActionOnRecord,
    FilterVendors, MaxItemsPerPage, OptionMenuDeleteItem, QueryParamsKey, SortByDefault,
    UserStatus, UserStatusList
} from '../../constant/AppConstant';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import VendorDetails from './VendorDetails';
import AssetsService from '../../../services/AssetsService';
import PopupConfirmation from '../../../helper/popup-confirmation';
import { isRoleSuperAdmin } from '../../../services/AuthService';

const Vendors = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const isSuperAdmin = isRoleSuperAdmin();
    const [menuOptions, setMenuOptions] = useState(UserStatusList);
    const [vendors, setVendors] = useState([]);
    const details = useRef();
    const deleteConfirmation = useRef();
    const [loading, setLoading] = useState(false);
    const [sortBySelected, setSortBySelected] = useState();
    const [filterSelected, setFilterSelected] = useState();
    const [keyword, setKeyword] = useState('');
    // Paging
    const [currentPage, setCurrentPage] = useState(1);
    const [paging, setPaging] = useState(null);

    let query = {};

    const renderPageIndex = (totalPage) => {
        const numPage = [...Array(totalPage + 1).keys()].slice(1)

        return numPage.map((n, i) => (
            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                onClick={() => changeCPage(n)}
            >
                {n}
            </Link>
        ));
    };

    const sortByAction = (item) => {
        setSortBySelected(item);
        findVendors(keyword, filterSelected, item);
    };

    const filterAction = (item) => {
        setFilterSelected(item);
        findVendors(keyword, item, sortBySelected);
    };

    function prePage() {
        if (currentPage !== 1) {
            query[QueryParamsKey.PAGE] = currentPage - 1;
            setCurrentPage(currentPage - 1);
            findVendors(keyword, filterSelected, sortBySelected);
        }
    }

    function changeCPage(id) {
        query[QueryParamsKey.PAGE] = id;
        setCurrentPage(id);
        findVendors(keyword, filterSelected, sortBySelected);
    }

    function nextPage() {
        if (paging && paging.pageCount && currentPage !== paging.pageCount) {
            query[QueryParamsKey.PAGE] = currentPage + 1;
            setCurrentPage(currentPage + 1);
            findVendors(keyword, filterSelected, sortBySelected);
        }
    }

    const onSearchByKey = (e) => {
        if (e.key === 'Enter') {
            findVendors(keyword, filterSelected, sortBySelected);
        }
    }

    // Load all vendors available
    const findVendors = async (keyword, filter, sortBy) => {
        try {
            setLoading(true);
            query[QueryParamsKey.TAKE] = MaxItemsPerPage;
            query[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
            query[QueryParamsKey.FILTER_BY] = filter ? filter.key : '';
            query[QueryParamsKey.SORT_BY] = sortBy ? sortBy.key : '';

            await VendorsService.findVendors(query)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response.paging) {
                            setPaging(response.paging);
                        }

                        setVendors(response.data);
                        // Get signed URL
                        getLogoURLs(response.data);
                    } else {
                        setVendors([]);
                    }
                })
                .catch(err => {
                    setVendors([]);
                    setLoading(false);
                });
        } catch (err) {
            setVendors([]);
            setLoading(false);
        }
    };

    const getStatusLabel = (ven) => {
        let status;

        if (ven && ven.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (ven.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    useEffect(() => {
        // Define menu option status
        let opts = [];

        opts = opts.concat(UserStatusList);
        opts = opts.concat(OptionMenuDeleteItem);

        setMenuOptions([...opts]);

        // Find all vendors
        findVendors();
    }, []);

    /**
     * Dialog confirm delete
     * @param {*} vendor 
     */
    const showDialogDelete = (vendor) => {
        const title = `Delete Vendor: ${vendor.companyName}`;
        const message = `Are you sure you want to delete this vendor?`;

        deleteConfirmation.current.showModal(vendor, { title: title, message: message });
    }

    /**
     * Show vendor details
     * @param {*} id 
     */
    const showVendorDetails = async (id) => {
        try {
            // setLoading(true);
            await VendorsService.getVendorDetails(id)
                .then((response) => {
                    // setLoading(false);
                    if (response) {
                        details.current.showDetailsModal(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            // setLoading(false);
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const getLogoURL = (item) => {
        return item.logo ? item.logo : '';
    };

    const getLogoURLs = async (lstVendors) => {
        try {
            if (lstVendors && lstVendors.length > 0) {
                for (let i = 0; i < lstVendors.length; i++) {
                    const ven = lstVendors[i];

                    if (ven.keyFile && ven.keyFile.logoKeyFile) {
                        const fileURL = AssetsService.getFileURL(ven.keyFile.logoKeyFile);

                        ven.logo = await AssetsService.getSignedURL(fileURL);                        
                    }
                }

                setVendors([...lstVendors]);
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const chooseOptionStatus = async (vendor, status) => {
        try {
            if (status === ActionOnRecord.DELETE) {
                showDialogDelete(vendor);
            } else {
                await VendorsService.updateVendorStatus(vendor.id, status)
                    .then((response) => {
                        if (response) {
                            // Reload data
                            findVendors(keyword, filterSelected, sortBySelected);

                            if (response.message) {
                                showToastSuccess(response.message);
                            }
                        }
                    });
            }
        } catch (error) {
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle delete vendor
     * @param {*} vendor 
     */
    const handleDeleteVendor = async (vendor) => {
        if (vendor == null || vendor.id == null || vendor.id === '')
            return false;

        await VendorsService.deteteById(vendor.id)
            .then((response) => {
                if (response) {
                    // Reload data
                    findVendors(keyword, filterSelected, sortBySelected);

                    if (response.message) {
                        showToastSuccess(response.message);
                    }
                }
            });
    }

    return (
        <>
            <MainPagetitle mainTitle="Vendor Management" pageTitle={'List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div className='col-xl-4 col-lg-5 col-md-6'>
                                            <div className="input-group search-area">
                                                <span className="input-group-text rounded-0">
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="8.78605" cy="8.78605" r="8.23951" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.5168 14.9447L17.7471 18.1667" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <input type="text" className="form-control rounded-0" placeholder="Search vendor . . ." onKeyDown={onSearchByKey} onChange={(e) => setKeyword(e.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <span className='d-inline-block fs-13 me-2'>Filter</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{filterSelected ? filterSelected.title : 'Filter'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {FilterVendors.map((itemFilter, index) => (
                                                            <Dropdown.Item onClick={() => filterAction(itemFilter)} key={index}>{itemFilter.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            <span className='d-inline-block fs-13 me-2'>Sort by</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{sortBySelected ? sortBySelected.title : 'Sort by'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {SortByDefault.map((itemSortBy, index) => (
                                                            <Dropdown.Item onClick={() => sortByAction(itemSortBy)} key={index}>{itemSortBy.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            {/* <Link to={"/add-vendor"} className="btn btn-primary btn-sm">+ Add Vendor</Link> */}
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Logo</th>
                                                    <th>Company</th>
                                                    <th>Business Reg No.</th>
                                                    <th>Status</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    {/* <th>Address</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vendors && vendors.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{index + 1}</span></td>
                                                        <td>{getLogoURL(item) ? <><img src={getLogoURL(item)} className="avatar avatar-md" alt="" onClick={() => showVendorDetails(item.id)} /></> : '-'}</td>
                                                        <td><span className='text-black auto-break-word' onClick={() => showVendorDetails(item.id)}>{item.companyName}</span></td>
                                                        <td><span className='text-black auto-break-word' onClick={() => showVendorDetails(item.id)}>{item.businessRegNo ? item.businessRegNo : '-'}</span></td>
                                                        <td><span>{getStatusLabel(item)}</span></td>
                                                        <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                        <td><span className="text-info">{item.contact ? item.contact : '-'}</span></td>
                                                        {/* <td>{item.address}</td> */}
                                                        <td>
                                                            {isSuperAdmin && <>
                                                                <Link to={{
                                                                    pathname: "/edit-vendor",
                                                                    search: `?${QueryParamsKey.ID}=${item.id}`,
                                                                    state: { fromDashboard: true }
                                                                }}><img src={IMAGES.IconEdit} className="avatar avatar-sm" alt="" /></Link>
                                                            </>}
                                                            <label onClick={() => showVendorDetails(item.id)}><img src={IMAGES.IconView} className="avatar avatar-sm mx-2" alt="" /></label>
                                                            {/* <Dropdown className="dropdown custom-dropdown mb-0">
                                                                <Dropdown.Toggle
                                                                    className="btn sharp btn-primary tp-btn i-false"
                                                                    data-toggle="dropdown">{SVGICON.MenuDot}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                    {menuOptions.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        chooseOptionStatus(item, st.key);
                                                                    }}>{st.title}</Dropdown.Item>))}
                                                                </Dropdown.Menu>
                                                            </Dropdown> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {vendors ? vendors.length : 0} of {paging && paging.total ? paging.total : 0} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {paging && paging.pageCount && renderPageIndex(paging.pageCount)}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            <VendorDetails ref={details} title="Vendor Details" />
            <PopupConfirmation ref={deleteConfirmation} onConfirm={handleDeleteVendor} />
        </>
    );
};

export default Vendors;