import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import { AccountTabKeys, AllTabsUserDetails, AllTabsVendorAdminDetails, Roles, UserStatus, UserStatusList } from '../../constant/AppConstant';
import AssetsService from '../../../services/AssetsService';
import { IMAGES } from '../../constant/theme';

const UserDetails = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [tabsDisplay, setTabsDisplay] = useState(AllTabsUserDetails);

    const closeToggle = () => {
        setUserDetails(null);
        setTabsDisplay(AllTabsUserDetails);
    }

    useImperativeHandle(ref, () => ({
        showDetailsModal(user) {
            setUserDetails(user);

            if (user && user.vendorId && user.vendorInfo) {
                setTabsDisplay(AllTabsVendorAdminDetails);
            }

            setDisplay(true);
        }
    }));

    const getAvatarURL = () => {
        let fileURL = IMAGES.Avatar;

        if (userDetails) {
            fileURL = userDetails.logo && userDetails.logo !== '' ? userDetails.logo : IMAGES.Avatar;

            if (userDetails.keyFile && userDetails.keyFile.logoKeyFile) {
                fileURL = AssetsService.getFileURL(userDetails.keyFile.logoKeyFile);
            }
        }

        return fileURL;
    };

    const getRolesLabel = () => {
        if (userDetails && userDetails.roles) {
            return Roles.GetRoleName(userDetails.roles);
        }

        return '-';
    };

    const getStatusLabel = () => {
        let status;

        if (userDetails && userDetails.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (userDetails.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    const getVendorStatusLabel = (vendor) => {
        let status;

        if (vendor && vendor.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (vendor.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    return (
        <>
            <Offcanvas show={display} onHide={() => {
                closeToggle();
                setDisplay(false);
            }} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">{props.title}: <span className='text-danger'>{userDetails ? userDetails.name : ''}</span></h5>
                    <button type="button" className="btn-close" onClick={() => {
                        closeToggle();
                        setDisplay(false);
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xl={12}>
                                <Tab.Container defaultActiveKey="Preview">
                                    <Card name="default-tab" className="dz-card">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Preview">
                                                <Card.Body>
                                                    {/* <!-- Nav tabs --> */}
                                                    <div className="default-tab">
                                                        <Tab.Container defaultActiveKey={tabsDisplay[0].key}>
                                                            <Nav as="ul" className="nav-tabs">
                                                                {tabsDisplay.map((data, i) => (
                                                                    <Nav.Item as="li" key={i}>
                                                                        <Nav.Link eventKey={data.key}>
                                                                            <i className={`la la-${data.icon} me-2`} />
                                                                            {data.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                            </Nav>
                                                            <Tab.Content className="pt-4">
                                                                {tabsDisplay.map((data, i) => (
                                                                    <Tab.Pane eventKey={data.key} key={i}>
                                                                        {/* Basic Information */}
                                                                        {data.key === AccountTabKeys.BASIC_INFORMATION ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        {/* <div className="form-group mb-2 row">
                                                                                            <div className="col-lg-12">
                                                                                                <div className="dz-default dlab-message upload-img">
                                                                                                    <div className="dropzone">
                                                                                                        <div className="c-profile text-center">
                                                                                                            <img src={getAvatarURL()} alt="" className="rounded-circle logo-vendor" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label text-danger">User Type</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-danger">{getRolesLabel()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Name</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label">{userDetails ? userDetails.name : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Contact</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{userDetails && userDetails.phoneNumber ? userDetails.phoneNumber : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Email</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{userDetails && userDetails.email ? userDetails.email : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-4 col-form-label">Status</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{getStatusLabel()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                        {/* Vendor Information */}
                                                                        {data.key === AccountTabKeys.VENDOR_INFORMATION ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label text-danger">Vendor ID</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-danger">{userDetails && userDetails.vendorInfo ? userDetails.vendorId : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Company Name</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label">{userDetails && userDetails.vendorInfo ? userDetails.vendorInfo.companyName : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Business Reg No</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label">{userDetails && userDetails.vendorInfo ? userDetails.vendorInfo.businessRegNo : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Contact</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{userDetails && userDetails.vendorInfo && userDetails.vendorInfo.contact ? userDetails.vendorInfo.contact : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Email</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{userDetails && userDetails.vendorInfo && userDetails.vendorInfo.email ? userDetails.vendorInfo.email : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Address</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{userDetails && userDetails.vendorInfo && userDetails.vendorInfo.address ? userDetails.vendorInfo.address : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-4 col-form-label">Status</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{getVendorStatusLabel(userDetails.vendorInfo)}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                    </Tab.Pane>
                                                                ))}
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </Card.Body>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Card>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </div >
            </Offcanvas >
        </>
    );
});

export default UserDetails;