import React, { Fragment } from "react";

import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";

const MainNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  return (
    <Fragment>
      <NavHader />
      <Header />
      <SideBar />
    </Fragment>
  );
};

export default MainNav;
