import React, { useState } from "react";
import { Roles, RolesAvailable, SuperAdminRolesAvailable, UserStatusList } from "../../constant/AppConstant";
import { showToastWarning } from "../../../helper/helper";
import { AdminAccountModel } from "../../models/admin-info-model";
import { isRoleSuperAdmin } from "../../../services/AuthService";
import { TextApp, TextKey } from "../../../assets/text-app";

const AccountForm = ({ index, data, vendors, onUpdate }) => {
  let errorObj = new AdminAccountModel();
  const isSuperAdmin = isRoleSuperAdmin();
  const [accountModel, setAccountModel] = useState(data ? data : new AdminAccountModel());
  const [errors, setErrors] = useState(errorObj);
  const [listUserStatus, setListUserStatus] = useState(UserStatusList);
  const [listRolesAvailable, setListRolesAvailable] = useState(isSuperAdmin ? SuperAdminRolesAvailable : RolesAvailable);
  // const [vendorsFilter, setVendorsFilter] = useState(vendors);

  const handleInputProfile = (field, value) => {
    accountModel[field] = value;
    setAccountModel(accountModel);
    // Validate form
    AdminAccountModel.validate(accountModel, errorObj);
    setErrors({ ...errorObj });

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleInputProfileNoSpace = (field, value) => {
    validateNoSpace(value);
    accountModel[field] = value.replace(/\s+/g, "");
    setAccountModel(accountModel);
    // Validate form
    AdminAccountModel.validate(accountModel, errorObj);
    setErrors({ ...errorObj });

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleChooseUserStatus = (value) => {
    accountModel.status = value;
    setAccountModel(accountModel);
    setListUserStatus([...UserStatusList]);

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleChooseUserType = (value) => {
    accountModel.roles = value;
    setAccountModel(accountModel);

    if (isSuperAdmin) {
      setListRolesAvailable([...SuperAdminRolesAvailable]);
    } else {
      setListRolesAvailable([...RolesAvailable]);
    }

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleSelectVendor = (e) => {
    accountModel.vendorId = e.target.value;
    // Validate form
    AdminAccountModel.validate(accountModel, errorObj);
    setErrors({ ...errorObj });

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const validateNoSpace = (fieldValue) => {
    const tmpStr = fieldValue.split(' ');

    if (tmpStr && tmpStr.length > 1) {
      showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
    }
  }

  // const localSearchVendor = (key) => {
  //   const listVendors = vendors && vendors.length > 0 && key !== '' ? vendors.filter((v) => {
  //     return v.companyName.includes(key) || v.vendorId.includes(key);
  //   }) : vendors;

  //   setVendorsFilter(listVendors);
  // }

  return (
    <>
      <div className="form-validation">
        <div className="row">
          <div className="col-xl-6">
            <div className="row">
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-name-${index}`}>Name<span className="text-danger">*</span></label>
                <div className="col-lg-8">
                  <input type="text" className="form-control" id={`val-name-${index}`} name={`val-name-${index}`} value={accountModel.name} placeholder="" onChange={(e) => handleInputProfile('name', e.target.value)} />
                  {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-email-${index}`}>Email<span className="text-danger">*</span></label>
                <div className="col-lg-8">
                  <input type="email" className="form-control" id={`val-email-${index}`} name={`val-email-${index}`} value={accountModel.email} onChange={(e) => handleInputProfileNoSpace('email', e.target.value)} placeholder="" />
                  {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-password-${index}`}>Password<span className="text-danger">*</span></label>
                <div className="col-lg-8">
                  <input type="password" className="form-control" id={`val-password-${index}`} name={`val-password-${index}`} value={accountModel.password} onChange={(e) => handleInputProfile('password', e.target.value)} placeholder="" />
                  {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-confirm-password-${index}`}>Confirm Password<span className="text-danger">*</span></label>
                <div className="col-lg-8">
                  <input type="password" className="form-control" id={`val-confirm-password-${index}`} name={`val-confirm-password-${index}`} value={accountModel.confirmPassword} onChange={(e) => handleInputProfile('confirmPassword', e.target.value)} placeholder="" />
                  {errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-contact-${index}`}>Contact</label>
                <div className="col-lg-8">
                  <input type="tel" className="form-control" id={`val-contact-${index}`} name={`val-contact-${index}`} value={accountModel.phoneNumber} maxLength={12} onChange={(e) => handleInputProfileNoSpace('phoneNumber', e.target.value)} placeholder="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="row">
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-roles-${index}`}>User Type</label>
                <div className="col-lg-8">
                  <select className="form-control" id={`val-roles-${index}`} name={`val-roles-${index}`} value={accountModel.roles} onChange={(e) => handleChooseUserType(e.target.value)}>
                    {listRolesAvailable.length > 0 && (
                      (listRolesAvailable.map((v, i) => {
                        return (<option value={v.key} key={i}>{v.name}</option>);
                      }))
                    )}
                  </select>
                </div>
              </div>
              {accountModel.roles && accountModel.roles === Roles.VENDOR_ADMIN && <>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-reference-vendor-${index}`}>Vendor<span className="text-danger">*</span></label>
                  <div className="col-lg-8">
                    {/* <input type="text" className="form-control mb-2" id="val-search-vendor" name="searchVendor" placeholder="Search vendor" onChange={(e) => {
                      e.preventDefault();
                      localSearchVendor(e.target.value);
                    }} /> */}
                    <select className="form-control" id={`val-reference-vendor-${index}`} name={`val-reference-vendor-${index}`} onChange={handleSelectVendor} value={accountModel.vendorId}>
                      <option value="">Choose vendor</option>
                      {vendors && vendors.length > 0 && (
                        (vendors.map((v, i) => {
                          return (<option value={v.vendorId} key={i}>{v.companyName}</option>);
                        }))
                      )}
                    </select>
                    {errors.vendorId && <div className="text-danger fs-12">{errors.vendorId}</div>}
                  </div>
                </div></>}
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label" htmlFor={`val-status-${index}`}>Status</label>
                <div className="col-lg-8">
                  <select className="form-control text-danger" id={`val-status-${index}`} name={`val-status-${index}`} value={accountModel.status} onChange={(e) => handleChooseUserStatus(e.target.value)}>
                    {listUserStatus.length > 0 && (
                      (listUserStatus.map((v, i) => {
                        return (<option value={v.key} key={i}>{v.title}</option>);
                      }))
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountForm;
