import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Col, Dropdown, Offcanvas, Row } from 'react-bootstrap';
import { QueryParamsKey, RangeRecordsExportToFile } from '../../constant/AppConstant';
import ReportService from '../../../services/ReportService';
import Preloader from '../../layouts/Preloader';
import { showToastError } from '../../../helper/helper';
import { TextApp, TextKey } from '../../../assets/text-app';

// "paging": {
//     "page": 0,
//     "take": 2000,
//     "total": 23,
//     "pageCount": 1
// }
const ExportCustomers = forwardRef((props, ref) => {
    const rangeRecords = RangeRecordsExportToFile;
    const [display, setDisplay] = useState(false);
    const [queryCustomers, setQueryCustomers] = useState();
    const [chunkFiles, setChunkFiles] = useState([]);
    const [rangeSelected, setRangeSelected] = useState(2000);
    const [loading, setLoading] = useState(false);

    const closeToggle = () => {
    }

    useImperativeHandle(ref, () => ({
        showDetailsModal(query, paging) {
            setQueryCustomers(query);
            // calculateChunkFiles(query, paging);
            selectRecordsPerPage(query, rangeRecords[0]);
            setDisplay(true);
        }
    }));

    const calculateChunkFiles = (query, paging) => {
        let allFiles = [];

        if (query && paging) {
            if (paging.pageCount && paging.pageCount > 0) {
                for (let i = 0; i < paging.pageCount; i++) {
                    const queryOnPage = query;
                    const rangeStart = Number(paging.take) * i + 1;
                    const rangeEnd = Number(paging.take) * (i + 1);

                    queryOnPage[QueryParamsKey.PAGE] = i;
                    queryOnPage[QueryParamsKey.OFFSET] = rangeStart - 1;
                    queryOnPage[QueryParamsKey.TAKE] = Number(paging.take);
                    queryOnPage[QueryParamsKey.CHUNK_FILES] = false;

                    const fileContent = {
                        range: `${rangeStart} - ${rangeEnd > Number(paging.total) ? Number(paging.total) : rangeEnd}`,
                        url: ReportService.exportCustomersToFile(queryOnPage)
                    }

                    allFiles.push(fileContent);
                }
            }
        }

        setChunkFiles([...allFiles]);
    };

    const selectRecordsPerPage = (query, range) => {
        setRangeSelected(range);
        // Fetch chunk files
        fetchChunkFiles(query, range);
    };

    const fetchChunkFiles = async (query, range) => {
        try {
            const queryParams = query != null ? query : {};
            queryParams[QueryParamsKey.PAGE] = '';
            queryParams[QueryParamsKey.OFFSET] = '';
            queryParams[QueryParamsKey.TAKE] = range;
            queryParams[QueryParamsKey.CHUNK_FILES] = true;

            const exportURL = ReportService.exportCustomersToFile(queryParams);
            setLoading(true);

            await ReportService.getTotalChunkFilesExport(exportURL)
                .then((result) => {
                    setLoading(false);

                    if (result && result.paging) {
                        calculateChunkFiles(queryParams, result.paging)
                    } else {
                        showToastError(TextApp.getText(TextKey.can_not_export_to_file));
                    }

                    return result;
                });
            // window.open(url, "_blank", "noreferrer");
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    return (
        <>
            <Offcanvas show={display} onHide={() => {
                closeToggle();
                setDisplay(false);
            }} className="offcanvas-end customeoff canvas-sm" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">{props.title}</h5>
                    <button type="button" className="btn-close" onClick={() => {
                        closeToggle();
                        setDisplay(false);
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xl={12}>
                                <span className='d-inline-block'>
                                    <Dropdown className="task-dropdown-2">
                                        <Dropdown.Menu className='task-drop-menu'>
                                            {rangeRecords.map((itemFilter, index) => (
                                                <Dropdown.Item onClick={() => selectRecordsPerPage(queryCustomers, itemFilter)} key={index}>{itemFilter}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                        <Dropdown.Toggle as="div">{rangeSelected ? rangeSelected : 'Select'}</Dropdown.Toggle>
                                    </Dropdown>
                                </span>
                                <span className='fs-13 ms-2'>records per page</span>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xl={12}>
                                <table className="table sub-users-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th></th>
                                            <th>Range of records</th>
                                            <th></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chunkFiles && chunkFiles.length > 0 ? <>
                                            {chunkFiles.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td></td>
                                                    <td ><span>{item.range ? item.range : '-'}</span></td>
                                                    <td></td>
                                                    <td><span>{item.url ? <>
                                                        <a href={item.url} target='blank' className="btn btn-info btn-sm ms-2"><i className="fa fa-download"></i> Download</a>
                                                    </> : '-'}</span></td>
                                                </tr>
                                            ))}
                                        </> : <><tr><td colSpan={3}><label className='ms-2 mt-3'>No data</label></td></tr></>}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </div >
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            </Offcanvas >
        </>
    );
});

export default ExportCustomers;