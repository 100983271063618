import React, { useState } from "react";
import { UserStatus, UserStatusList } from "../../constant/AppConstant";
import { AdminAccountModel } from "../../models/admin-info-model";

const AdminAccountOnlyView = ({ index, data, onDelete, onUpdate }) => {
  let errorObj = new AdminAccountModel();
  const [accountModel, setAccountModel] = useState(data ? data : new AdminAccountModel());
  const [errors, setErrors] = useState(errorObj);

  const isDisabledField = () => {
    if (accountModel && accountModel.vendorId != null && accountModel.vendorId !== '') {
      return true;
    }

    return false;
  }

  const getStatusLabel = (acc) => {
    let status;

    if (acc && acc.status) {
      for (let i = 0; i < UserStatusList.length; i++) {
        if (acc.status === UserStatusList[i].key) {
          status = UserStatusList[i];
          break;
        }
      }
    }

    if (status) {
      return (<span className='pe-0 c-status'><i
        className=
        {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
          status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
      />{status.title}</span>);
    }

    return '-';
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <label className="fs-7 text-primary">Admin {index + 1}</label>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <div className="row">
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-name-${index}`}>Name<span className="text-danger">*</span></label>
                  <div className="col-lg-8">
                    <input type="text" className="form-control bg-disabled" id={`val-name-${index}`} name={`val-name-${index}`} value={accountModel.name} disabled={isDisabledField()} />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-email-${index}`}>Email<span className="text-danger">*</span></label>
                  <div className="col-lg-8">
                    <input type="text" className="form-control bg-disabled" id={`val-email-${index}`} name={`val-email-${index}`} value={accountModel.email} disabled={isDisabledField()} />
                    {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-contact-${index}`}>Contact</label>
                  <div className="col-lg-8">
                    <input type="tel" className="form-control bg-disabled" id={`val-contact-${index}`} name={`val-contact-${index}`} value={accountModel.phoneNumber} disabled={isDisabledField()} />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-status-${index}`}>Status</label>
                  <div className="col-lg-8">
                    <label>{getStatusLabel(accountModel)}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAccountOnlyView;
