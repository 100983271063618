import { Roles } from "../constant/AppConstant";

export class CreateListAccountsRequest {
    constructor() {
        this.adminAccounts = [];
    }
};

export class AdminAccountModel {
    constructor(roles = '') {
        this.vendorId = '';
        this.name = '';
        this.email = '';
        this.phoneNumber = '';
        this.password = '';
        this.confirmPassword = '';
        this.status = '';
        this.roles = roles;
    }

    static validate(obj, error, ignorePassword = false) {
        let result = true;

        if (obj.name == null || obj.name === '') {
            error.name = 'Name is required';
            result = false;
        }

        if (obj.email == null || obj.email === '') {
            error.email = 'Email is required';
            result = false;
        }

        if (!ignorePassword) {
            if (obj.password == null || obj.password === '') {
                error.password = 'Password is required';
                result = false;
            }

            if (obj.password !== obj.confirmPassword) {
                error.confirmPassword = 'Confirm Password and Password does not match';
                result = false;
            }
        }

        if (obj.roles && obj.roles === Roles.VENDOR_ADMIN) {
            if (obj.vendorId == null || obj.vendorId === '') {
                error.vendorId = 'Vendor is required';
                result = false;
            }
        }

        return result;
    }
}