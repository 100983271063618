/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Dropdown } from 'react-bootstrap';
import {
    ActionOnRecord,
    FilterVendors, MaxItemsPerPage, OptionMenuDeleteItem, QueryParamsKey, Roles, SortByDefault,
    UserStatus, UserStatusList
} from '../../constant/AppConstant';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import UserDetails from './UserDetails';
import AssetsService from '../../../services/AssetsService';
import PopupConfirmation from '../../../helper/popup-confirmation';
import UsersService from '../../../services/UsersService';
import { isRoleSuperAdmin } from '../../../services/AuthService';

const Users = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const isSuperAdmin = isRoleSuperAdmin();
    const [menuOptions, setMenuOptions] = useState(UserStatusList);
    const [users, setUsers] = useState([]);
    const details = useRef();
    const deleteConfirmation = useRef();
    const [loading, setLoading] = useState(false);
    const [sortBySelected, setSortBySelected] = useState();
    const [filterSelected, setFilterSelected] = useState();
    const [keyword, setKeyword] = useState('');
    // Paging
    const [currentPage, setCurrentPage] = useState(1);
    const [paging, setPaging] = useState(null);

    let query = {};

    const renderPageIndex = (totalPage) => {
        const numPage = [...Array(totalPage + 1).keys()].slice(1)

        return numPage.map((n, i) => (
            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                onClick={() => changeCPage(n)}
            >
                {n}
            </Link>
        ));
    };

    const sortByAction = (item) => {
        setSortBySelected(item);
        findUsers(keyword, filterSelected, item);
    };

    const filterAction = (item) => {
        setFilterSelected(item);
        findUsers(keyword, item, sortBySelected);
    };

    function prePage() {
        if (currentPage !== 1) {
            query[QueryParamsKey.PAGE] = currentPage - 1;
            setCurrentPage(currentPage - 1);
            findUsers(keyword, filterSelected, sortBySelected);
        }
    }

    function changeCPage(id) {
        query[QueryParamsKey.PAGE] = id;
        setCurrentPage(id);
        findUsers(keyword, filterSelected, sortBySelected);
    }

    function nextPage() {
        if (paging && paging.pageCount && currentPage !== paging.pageCount) {
            query[QueryParamsKey.PAGE] = currentPage + 1;
            setCurrentPage(currentPage + 1);
            findUsers(keyword, filterSelected, sortBySelected);
        }
    }

    const onSearchByKey = (e) => {
        if (e.key === 'Enter') {
            findUsers(keyword, filterSelected, sortBySelected);
        }
    }

    // Load all vendors available
    const findUsers = async (keyword, filter, sortBy) => {
        try {
            setLoading(true);
            query[QueryParamsKey.TAKE] = MaxItemsPerPage;
            query[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
            query[QueryParamsKey.FILTER_BY] = filter ? filter.key : '';
            query[QueryParamsKey.SORT_BY] = sortBy ? sortBy.key : '';

            UsersService.findUsers(query)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response.paging) {
                            setPaging(response.paging);
                        }

                        setUsers(response.data);
                    } else {
                        setUsers([]);
                    }
                })
                .catch(err => {
                    setUsers([]);
                    setLoading(false);
                });
        } catch (err) {
            setUsers([]);
            setLoading(false);
        }
    };

    const getRolesLabel = (user) => {
        if (user && user.roles) {
            return Roles.GetRoleName(user.roles);
        }

        return '-';
    };

    const getStatusLabel = (user) => {
        let status;

        if (user && user.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (user.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    const isEnableEditAccount = (acc) => {
        if (acc == null)
            return false;

        if (acc.roles === Roles.SUPER_ADMIN) {
            return isSuperAdmin;
        } else {
            return true;
        }
    };

    useEffect(() => {
        // Define menu option status
        let opts = [];

        opts = opts.concat(UserStatusList);
        opts = opts.concat(OptionMenuDeleteItem);

        setMenuOptions([...opts]);

        // Find all users
        findUsers();
    }, []);

    /**
     * Dialog confirm delete
     * @param {*} user 
     */
    const showDialogDelete = (user) => {
        const title = `Delete Account: ${user.name}`;
        const message = `Are you sure you want to delete this account?`;

        deleteConfirmation.current.showModal(user, { title: title, message: message });
    }

    /**
     * Show user details
     * @param {*} id 
     */
    const showUserDetails = async (id) => {
        try {
            // setLoading(true);
            await UsersService.getUserDetails(id)
                .then((response) => {
                    // setLoading(false);
                    if (response) {
                        details.current.showDetailsModal(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            // setLoading(false);
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const getAvatarURL = (ven) => {
        let fileURL = '';

        if (ven) {
            fileURL = ven.logo && ven.logo !== '' ? ven.logo : '';

            if (ven.keyFile && ven.keyFile.logoKeyFile) {
                fileURL = AssetsService.getFileURL(ven.keyFile.logoKeyFile);
            }
        }

        return fileURL;
    };

    const chooseOptionStatus = async (vendor, status) => {
        try {
            if (status === ActionOnRecord.DELETE) {
                showDialogDelete(vendor);
            } else {
                await UsersService.updateUserStatus(vendor.id, status)
                    .then((response) => {
                        if (response) {
                            // Reload data
                            findUsers(keyword, filterSelected, sortBySelected);

                            if (response.message) {
                                showToastSuccess(response.message);
                            }
                        }
                    });
            }
        } catch (error) {
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle delete account
     * @param {*} account
     */
    const handleDeleteAccount = async (user) => {
        if (user == null || user.id == null || user.id === '')
            return false;

        await UsersService.deteteUserById(user.id)
            .then((response) => {
                if (response) {
                    // Reload data
                    findUsers(keyword, filterSelected, sortBySelected);

                    if (response.message) {
                        showToastSuccess(response.message);
                    }
                }
            });
    }

    return (
        <>
            <MainPagetitle mainTitle="Admin Management" pageTitle={'List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div className='col-xl-4 col-lg-5 col-md-6'>
                                            <div className="input-group search-area">
                                                <span className="input-group-text rounded-0">
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="8.78605" cy="8.78605" r="8.23951" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.5168 14.9447L17.7471 18.1667" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <input type="text" className="form-control rounded-0" placeholder="Search name, email, contact . . ." onKeyDown={onSearchByKey} onChange={(e) => setKeyword(e.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <span className='d-inline-block fs-13 me-2'>Filter</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{filterSelected ? filterSelected.title : 'Filter'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {FilterVendors.map((itemFilter, index) => (
                                                            <Dropdown.Item onClick={() => filterAction(itemFilter)} key={index}>{itemFilter.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            <span className='d-inline-block fs-13 me-2'>Sort by</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{sortBySelected ? sortBySelected.title : 'Sort by'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {SortByDefault.map((itemSortBy, index) => (
                                                            <Dropdown.Item onClick={() => sortByAction(itemSortBy)} key={index}>{itemSortBy.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            <Link to={"/add-account"} className="btn btn-primary btn-sm">+ Add Account</Link>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    {/* <th>Avatar</th> */}
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th>User Type</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    <th>Vendor</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users && users.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{index + 1}</span></td>
                                                        {/* <td>{getAvatarURL(item) ? <><img src={getAvatarURL(item)} className="avatar avatar-md" alt="" /></> : '-'}</td> */}
                                                        <td><span className='text-black auto-break-word' onClick={() => showUserDetails(item.id)}>{item.name}</span></td>
                                                        <td><span>{getStatusLabel(item)}</span></td>
                                                        <td><span>{getRolesLabel(item)}</span></td>
                                                        <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                        <td><span className="text-info">{item.phoneNumber ? item.phoneNumber : '-'}</span></td>
                                                        <td>{item.vendorId && item.vendorInfo ? <>{item.vendorInfo.companyName}</> : '-'}</td>
                                                        <td>
                                                            {isEnableEditAccount(item) && <>
                                                                <Link to={{
                                                                    pathname: "/edit-account",
                                                                    search: `?${QueryParamsKey.ID}=${item.id}`,
                                                                    state: { fromDashboard: true }
                                                                }}><img src={IMAGES.IconEdit} className="avatar avatar-sm" alt="" /></Link>
                                                                <label onClick={() => showUserDetails(item.id)}><img src={IMAGES.IconView} className="avatar avatar-sm mx-2" alt="" /></label>
                                                                <Dropdown className="dropdown custom-dropdown mb-0">
                                                                    <Dropdown.Toggle
                                                                        className="btn sharp btn-primary tp-btn i-false"
                                                                        data-toggle="dropdown">{SVGICON.MenuDot}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                        {menuOptions.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            chooseOptionStatus(item, st.key);
                                                                        }}>{st.title}</Dropdown.Item>))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </>}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {users && users.length > 0 ? <>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {users ? users.length : 0} of {paging && paging.total ? paging.total : 0} entries
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                    id="example2_paginate">
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={prePage}>
                                                        <i className="fa-solid fa-angle-left" />
                                                    </Link>
                                                    <span>{paging && paging.pageCount && renderPageIndex(paging.pageCount)}</span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={nextPage}>
                                                        <i className="fa-solid fa-angle-right" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </> : <><label className='m-4'>No data</label></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            <UserDetails ref={details} title="User Details" />
            <PopupConfirmation ref={deleteConfirmation} onConfirm={handleDeleteAccount} />
        </>
    );
};

export default Users;