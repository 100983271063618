import { CustomersActionType } from "../actions/CustomersActions";

const initialState = {
    customers: [],
};

function CustomersReducer(state = initialState, action) {
    if (action == null || action.type == null || action.type === '')
        return state;

    switch (action.type) {
        case CustomersActionType.GET_CUSTOMERS_ACTION:
            const customers = action.payload;   // [...state.customers];

            return {
                ...state,
                customers
            };

        default:
            return state;
    }
}

export default CustomersReducer;