import { ApiMethod, QueryParamsKey } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod } from './ApiService';

export default class VendorsService {
    /**
     * Find vendors
     * @param {*} queryParams 
     * @returns 
     */
    static async findVendors(queryParams = {}, isPaging = true, dataResponseOnly = false) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.ListVendors}`;

        queryParams[QueryParamsKey.IS_PAGING] = isPaging;

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: queryParams, dataResponseOnly: dataResponseOnly })
            .then((response) => {
                return response;
            })
            .catch(err => {
                return null;
            });
    }

    /**
    * Get all vendor's customers
    * @param {*} queryParams 
    * @returns {data: [], paging: {}}
    * "paging": {
       "page": number,
       "take": number,
       "total": number,
       "pageCount": number,
       "hasPreviousPage": boolean,
       "hasNextPage": boolean
   }
    */
    static async getVendorCustomers(queryParams = {}) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.ListCustomers}`;

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: queryParams, dataResponseOnly: false})
            .then((response) => {
                return response;
            });
            // .catch(err => {
            //     return err;
            //     // return null;
            // });
    }

    /**
     * Get vendor details
     * @param id 
     * @returns 
     */
    static async getVendorDetails(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.Details}/${id}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Create new vendor
     * @param {*} vendorInfo 
     * @returns 
     */
    static async createNewVendor(vendorInfo) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.NewVendor}`;

        return Api.invokeApi(url, HttpMethod.POST, { payload: vendorInfo, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update vendor
     * @param id 
     * @param {*} vendorInfo 
     * @returns
     */
    static async updateVendor(id, vendorInfo) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.UpdateVendor}/${id}`;

        return Api.invokeApi(url, HttpMethod.PUT, { payload: vendorInfo, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update vendor's status
     * @param {*} id 
     * @param {*} status 
     * @returns 
     */
    static async updateVendorStatus(id, status) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.UpdateStatus}/${id}`;
        const data = {
            status: status
        };

        return Api.invokeApi(url, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Delete vendor by id
     * @param {*} id 
     * @returns 
     */
    static async deteteById(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.DeleteById}/${id}`;

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Get vendor's customer details
     * @param customerId 
     * @returns 
     */
    static async getVendorCustomerDetails(customerId) {
        const detailsCustomerURL = `${ApiEndpoint}/${ApiMethod.Vendors.GetVendorCustomerDetails}/${customerId}`;

        return Api.invokeApi(detailsCustomerURL, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

     /**
     * Update customer
     * @param cusId 
     * @param {*} customerInfo 
     * @returns
     */
     static async updateCustomer(cusId, customerInfo) {
        const updateCustomerURL = `${ApiEndpoint}/${ApiMethod.Vendors.UpdateVendorCustomer}/${cusId}`;

        return Api.invokeApi(updateCustomerURL, HttpMethod.PUT, { payload: customerInfo, headers: Api.makeAuthorizationHeaderFormData(), dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
    * Delete customer by id
    * @param {*} id 
    * @returns 
    */
    static async deteteCustomerById(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Vendors.DeleteVendorCustomerById}/${id}`;

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

}
