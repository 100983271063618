/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import { AdminAccountModel } from '../../models/admin-info-model';
import UsersService from '../../../services/UsersService';
import ProfileForm from './ProfileForm';
import { TextApp, TextKey } from '../../../assets/text-app';

const MyProfile = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const initialized = useRef(false);
	const [loading, setLoading] = useState(false);
	// Form
	const [adminAccount, setAdminAccount] = useState();

	const onUpdateProfileInfo = (index, data) => {
		setAdminAccount(data);
	};

	const loadAccountDetails = async () => {
		try {
			setLoading(true);

			await UsersService.getProfile()
				.then((response) => {
					setLoading(false);

					if (response) {
						setAdminAccount(response);
					} else {
						if (response && response.message) {
							showToastError(response.message);
						}
					}
				});
		} catch (error) {
			setLoading(false);

			if (error && error.message) {
				showToastError(error.message);
			}
		}
	};

	useEffect(() => {
		if (initialized.current)
			return;

		initialized.current = true;
		// Get account details
		loadAccountDetails();
	}, [navigate, location]);

	/**
	 * Handle submit all information to UCD
	 * @param {*} e 
	 * @returns 
	 */
	const handleSubmitInfo = (e) => {
		e.preventDefault();

		// Validate data
		if (!validateData()) {
			showToastWarning(TextApp.getText(TextKey.requied_fields));
			return;
		}

		// Send data to UCD
		requestUpdateAccountInfo(JSON.stringify(adminAccount));
	};

	/**
	 * Validate form
	 * @returns
	 */
	const validateData = () => {
		let errorAdminAcc = new AdminAccountModel();
		let validateAdminAcc = AdminAccountModel.validate(adminAccount, errorAdminAcc);

		return validateAdminAcc;
	}

	/**
	* Request update account info
	* @param {} data 
	*/
	const requestUpdateAccountInfo = async (data) => {
		try {
			setLoading(true);

			// Create new account
			await UsersService.updateMyProfile(data)
				.then((response) => {
					setLoading(false);

					if (response && response.success) {
						showToastSuccess(TextApp.getText(TextKey.profile_updated_successfully));
						// resetForm();
						// navigate(-1);
						// navigate('/dashboard', { replace: true });
					} else {
						if (response && response.message) {
							showToastError(response.message);
						}
					}
				});
		} catch (error) {
			setLoading(false);

			if (error && error.message) {
				showToastError(error.message);
			}
		}
	};

	return (
		<>
			<MainPagetitle mainTitle="My Account" pageTitle="Profile" parentTitle="Home" />
			<div className="container-fluid">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body p-3">
							<div className="d-flex align-items-center justify-content-between mb-4">
								<h4 className="heading mb-0">My Profile</h4>
							</div>
							<div className="dataTables_wrapper no-footer">
								<Row>
									<Col xl={12}>
										<Tab.Container defaultActiveKey="Preview">
											<Card name="default-tab" className="dz-card">
												<Tab.Content>
													<Tab.Pane eventKey="Preview">
														<Card.Body>
															{/* <!-- Nav tabs --> */}
															<div className="default-tab">
																{adminAccount && <><ProfileForm key={adminAccount.id} index={0} onUpdate={onUpdateProfileInfo} data={adminAccount} /></>}
															</div>
														</Card.Body>
													</Tab.Pane>
												</Tab.Content>
											</Card>
										</Tab.Container>
									</Col>
								</Row>
								<div className="form-group row">
									<div className="col-xl-12 text-end">
										<button className="btn btn-primary" onClick={handleSubmitInfo}>Update Profile</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loading && (<div className="dialog-blur">{<Preloader />}</div>)}
			</div >
		</>
	)
}

export default MyProfile;