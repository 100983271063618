export class ConfigInfoModel {
    constructor(type = '') {
        this.type = type;
        this.title = '';
        this.keyword = '';
        this.value = '';
        this.description = '';
        this.status = '';
    }

    static validate(obj, error) {
        let result = true;

        if (obj.type == null || obj.type === '') {
            error.type = 'Type is required';
            result = false;
        }

        if (obj.title == null || obj.title === '') {
            error.title = 'Title is required';
            result = false;
        }

        // if (obj.keyword == null || obj.keyword === '') {
        //     error.keyword = 'Keyword is required';
        //     result = false;
        // }

        if (obj.value == null || obj.value === '') {
            error.value = 'Value is required';
            result = false;
        }

        return result;
    }
}