
import { LOCAL_STORAGE_KEY } from '../../services/ApiService';
import {
    login, saveTokenInLocalStorage, signUp
} from '../../services/AuthService';

export const AuthActionType = {
    SIGNUP_CONFIRMED_ACTION: '[signup action] confirmed signup',
    SIGNUP_FAILED_ACTION: '[signup action] failed signup',
    LOGIN_CONFIRMED_ACTION: '[login action] confirmed login',
    LOGIN_FAILED_ACTION: '[login action] failed login',
    LOADING_TOGGLE_ACTION: '[Loading action] toggle loading',
    LOGOUT_ACTION: '[Logout action] logout action'
}

export function signupAction(email, password, navigate) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = error && error.message ? error.message : '';
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logoutAction(navigate) {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);

    if (navigate != null) {
        //history.push('/login');
        navigate('/login');
    }

    return { type: AuthActionType.LOGOUT_ACTION };
    // return (dispatch) => {
    //     logout()
    //         .then((response) => {
    //             dispatch({ type: AuthActionType.LOGOUT_ACTION });
    //         });
    // }
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response && response.accessToken) {
                    saveTokenInLocalStorage(response);
                    dispatch(loginConfirmedAction(response));
                    navigate('/dashboard');
                } else {
                    dispatch(loginFailedAction('Login failed. Please try again.'));
                }
            })
            .catch((error) => {
                const errorMessage = error && error.message ? error.message : '';
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: AuthActionType.LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: AuthActionType.LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: AuthActionType.SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: AuthActionType.SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: AuthActionType.LOADING_TOGGLE_ACTION,
        payload: status,
    };
}