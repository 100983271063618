import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

// props: {
//     onConfirm: function
// }
// ref: {
//     showModal(data, { title, mesage });
// }
const PopupConfirmation = forwardRef((props, ref) => {
    const [isShow, setIsShow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [data, setData] = useState(null);

    useImperativeHandle(ref, () => ({
        showModal(data, { title, message }) {
            setTitle(title);
            setMessage(message);
            setIsShow(true);
            setData(data);
        }
    }));

    const onConfirm = () => {
        if (props && props.onConfirm) {
            props.onConfirm(data);
        }
    }

    return (
        <>
            <Modal className="fade" show={isShow} onHide={() => { setIsShow(false) }}>
                <Modal.Header>
                    <Modal.Title className="fs-5">{title ? title : ''}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => { setIsShow(false) }}></Button>
                </Modal.Header>
                <Modal.Body className="fs-6">{message ? message : ''}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary light" onClick={() => { setIsShow(false) }}>No</Button>
                    <Button variant="danger" onClick={(e) => {
                        setIsShow(false);
                        onConfirm();
                    }}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default PopupConfirmation;
