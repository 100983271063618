import { ApiMethod, QueryParamsKey } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod } from './ApiService';

export default class UsersService {
    /**
     * Find users
     * @param {*} queryParams 
     * @returns 
     */
    static async findUsers(queryParams = {}, isPaging = true, dataResponseOnly = false) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.ListAccounts}`;

        queryParams[QueryParamsKey.IS_PAGING] = isPaging;

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: queryParams, dataResponseOnly: dataResponseOnly })
            .then((response) => {
                return response;
            })
            .catch(err => {
                return null;
            });
    }

    /**
    * Get all vendor's admin account
    */
    static async getUsersByVendorId(vendorId, keyword = '') {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.ListAccountsByVendorId}`;
        const queryParams = {
            vendor_id: vendorId,
            keyword: keyword
        }

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: queryParams })
            .then((response) => {
                return response;
            })
            .catch(err => {
                return null;
            });
    }

    /**
     * Get my details
     * @returns 
     */
    static async getProfile() {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.Profile}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Get user details
     * @param id 
     * @returns 
     */
    static async getUserDetails(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.Details}/${id}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Create new account
     * @param {*} data 
     * @returns 
     */
    static async createListAccount(data) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.CreateListAccount}`;

        return Api.invokeApi(url, HttpMethod.POST, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Create new account
     * @param {*} data 
     * @returns 
     */
    static async createNewAccount(data) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.NewAccount}`;

        return Api.invokeApi(url, HttpMethod.POST, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Generate api access key
     * @param id 
     * @param {*} data 
     * @returns
     */
    static async generateApiKey(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.GenerateNewApiKey}/${id}`;

        return Api.invokeApi(url, HttpMethod.PUT, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update account
     * @param id 
     * @param {*} data 
     * @returns
     */
    static async updateAccount(id, data) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.UpdateAccount}/${id}`;

        return Api.invokeApi(url, HttpMethod.PUT, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update my profile
     * @param {*} data 
     * @returns
     */
    static async updateMyProfile(data) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.UpdateProfile}`;

        return Api.invokeApi(url, HttpMethod.PUT, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update user's status
     * @param {*} id 
     * @param {*} status 
     * @returns 
     */
    static async updateUserStatus(id, status) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.UpdateAccountStatus}/${id}`;
        const data = {
            status: status
        };

        return Api.invokeApi(url, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Delete user by id
     * @param {*} id 
     * @returns 
     */
    static async deteteUserById(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Accounts.DeleteById}/${id}`;

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Request OTP reset password
     * @param {} email 
     * @returns 
     */
    static async requestOTPResetPassword(email) {
        const url = `${ApiEndpoint}/${ApiMethod.Auth.RequestOTPResetPassword}`;
        const data = {
            email: email
        };

        return Api.invokeApi(url, HttpMethod.POST, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Confirm reset password
     * @param {} email 
     * @param {} otp
     * @returns 
     */
    static async confirmResetPassword(email, otp) {
        const url = `${ApiEndpoint}/${ApiMethod.Auth.ConfirmResetPassword}`;
        const data = {
            email: email,
            otp: otp
        };

        return Api.invokeApi(url, HttpMethod.POST, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }
}
