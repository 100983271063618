import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import {
    CustomerTabKeys, DOCS_FILE_EXTENSION, LocationFormat, RegionsGroupDefault,
    TabsCustomerNonRefIDOnlyView,
    UserGroupDefault, VerificationStatus, VerificationStatusList
} from '../../constant/AppConstant';
import AssetsService from '../../../services/AssetsService';
import { IMAGES } from '../../constant/theme';
import { formatDistrictWard } from '../../../helper/location-helper';

const VendorCustomerDetails = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [displayFileURL, setDisplayFileURL] = useState('');
    const [tabsCustomerInfo, setTabsCustomerInfo] = useState(TabsCustomerNonRefIDOnlyView);

    const closeToggle = () => {
        setDisplayFileURL('');
    }

    useImperativeHandle(ref, () => ({
        showDetailsModal(customer) {
            setCustomerDetails(customer);
            setDisplay(true);
        }
    }));

    const getProfileAddress = () => {
        let fullAddress = '';

        if (customerDetails && customerDetails.profileInfo) {
            fullAddress = customerDetails.profileInfo.address;

            if (customerDetails.profileInfo.ward) {
                const wardStr = formatDistrictWard(customerDetails.profileInfo.ward, LocationFormat.WARD_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${wardStr}`;
            }
            if (customerDetails.profileInfo.district) {
                const districtStr = formatDistrictWard(customerDetails.profileInfo.district, LocationFormat.DISTRICT_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${districtStr}`;
            }
            // if (customerDetails.profileInfo.city) {
            //     fullAddress = `${fullAddress}, ${customerDetails.profileInfo.city}`;
            // }
            // if (customerDetails.profileInfo.state) {
            //     fullAddress = `${fullAddress}, ${customerDetails.profileInfo.state}`;
            // }
            // if (customerDetails.profileInfo.postalCode) {
            //     fullAddress = `${fullAddress} ${customerDetails.profileInfo.postalCode}`;
            // }
        }

        return fullAddress ? fullAddress : '-';
    };

    const getBillingAddress = () => {
        let fullAddress = '';

        if (customerDetails && customerDetails.billingInfo) {
            fullAddress = customerDetails.billingInfo.address;

            if (customerDetails.billingInfo.ward) {
                const wardStr = formatDistrictWard(customerDetails.billingInfo.ward, LocationFormat.WARD_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${wardStr}`;
            }
            if (customerDetails.billingInfo.district) {
                const districtStr = formatDistrictWard(customerDetails.billingInfo.district, LocationFormat.DISTRICT_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${districtStr}`;
            }
            // if (customerDetails.billingInfo.city) {
            //     fullAddress = `${fullAddress}, ${customerDetails.billingInfo.city}`;
            // }
            // if (customerDetails.billingInfo.state) {
            //     fullAddress = `${fullAddress}, ${customerDetails.billingInfo.state}`;
            // }
        }

        return fullAddress ? fullAddress : '-';
    };

    const getShippingAddress = () => {
        let fullAddress = '';

        if (customerDetails && customerDetails.shippingInfo) {
            fullAddress = customerDetails.shippingInfo.address;

            if (customerDetails.shippingInfo.ward) {
                const wardStr = formatDistrictWard(customerDetails.shippingInfo.ward, LocationFormat.WARD_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${wardStr}`;
            }
            if (customerDetails.shippingInfo.district) {
                const districtStr = formatDistrictWard(customerDetails.shippingInfo.district, LocationFormat.DISTRICT_NUMBER_PREFIX_VN);
                fullAddress = `${fullAddress}, ${districtStr}`;
            }
            // if (customerDetails.shippingInfo.city) {
            //     fullAddress = `${fullAddress}, ${customerDetails.shippingInfo.city}`;
            // }
            // if (customerDetails.shippingInfo.state) {
            //     fullAddress = `${fullAddress}, ${customerDetails.shippingInfo.state}`;
            // }
            // if (customerDetails.shippingInfo.postalCode) {
            //     fullAddress = `${fullAddress} ${customerDetails.shippingInfo.postalCode}`;
            // }
        }

        return fullAddress ? fullAddress : '-';
    };

    const getBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.businessLicenseURL ? customerDetails.profileInfo.businessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.businessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.businessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPharmaBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.pharmaBusinessLicenseURL ? customerDetails.profileInfo.pharmaBusinessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.pharmaBusinessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.pharmaBusinessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getAPCFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.apcLicenseURL ? customerDetails.profileInfo.apcLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.apcLicenseFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.apcLicenseFile);
            }
        }

        return fileURL;
    };

    const getPoisonALicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.poisonALicenseURL ? customerDetails.profileInfo.poisonALicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.poisonALicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.poisonALicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getSignedFileURL = async (url) => {
        try {
            const signedResult = url ? await AssetsService.getSignedURL(url, true) : null;

            if (signedResult) {
                if (signedResult.ext && DOCS_FILE_EXTENSION.indexOf(signedResult.ext) >= 0) {
                    var a = document.createElement('a');
                    a.href = AssetsService.getDocumentFileURL(signedResult.keyFile);
                    a.target = '_blank';
                    a.click();
                } else {
                    setDisplayFileURL(signedResult.url);
                }
            } else {
                setDisplayFileURL('');
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const getVerifyLabel = () => {
        let status;

        if (customerDetails && customerDetails.verification) {
            for (let i = 0; i < VerificationStatusList.length; i++) {
                if (customerDetails.verification === VerificationStatusList[i].key) {
                    status = VerificationStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            if (status.key === VerificationStatus.APPROVED) {
                return (<span><img src={IMAGES.IconActive} className="icon-status mx-2" alt="" />{status.title}</span>);
            } else {
                return status.title;
            }
        }

        return '';
    };

    return (
        <>
            <Offcanvas show={display} onHide={() => {
                closeToggle();
                setDisplay(false);
            }} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">{props.title}</h5>
                    <button type="button" className="btn-close" onClick={() => {
                        closeToggle();
                        setDisplay(false);
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xl={12}>
                                <Tab.Container defaultActiveKey="Preview">
                                    <Card name="default-tab" className="dz-card">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Preview">
                                                <Card.Body>
                                                    {/* <!-- Nav tabs --> */}
                                                    <div className="default-tab">
                                                        <Tab.Container defaultActiveKey={tabsCustomerInfo[0].key}>
                                                            <Nav as="ul" className="nav-tabs">
                                                                {tabsCustomerInfo.map((data, i) => (
                                                                    <Nav.Item as="li" key={i}>
                                                                        <Nav.Link eventKey={data.key}>
                                                                            <i className={`la la-${data.icon} me-2`} />
                                                                            {data.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                            </Nav>
                                                            <Tab.Content className="pt-4">
                                                                {tabsCustomerInfo.map((data, i) => (
                                                                    <Tab.Pane eventKey={data.key} key={i}>
                                                                        {/* Basic Information */}
                                                                        {data.key === CustomerTabKeys.BASIC_INFORMATION ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-6">
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Customer Code</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted">{customerDetails ? customerDetails.customerCode : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Forensic Group</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails ? UserGroupDefault.GetName(customerDetails.userGroup) : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Company Name</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails ? customerDetails.companyName : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Representative Name</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails ? customerDetails.representativeName : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Contact</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails ? customerDetails.phoneNumber : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Email</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.email ? customerDetails.email : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Customer Group</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.regionGroup ? customerDetails.regionGroup : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Address</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{getProfileAddress()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Address Line 2</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.address2 ? customerDetails.profileInfo.address2 : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="form-group row">
                                                                                            <label className="col-lg-5 col-form-label">City</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.city ? customerDetails.profileInfo.city : '-'}</label>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">State</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.state ? customerDetails.profileInfo.state : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Postal Code</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.postalCode ? customerDetails.profileInfo.postalCode : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Country</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.country ? customerDetails.profileInfo.country : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6">
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">MMC Number</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.mmcNo ? customerDetails.profileInfo.mmcNo : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Specialty</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.specialty ? customerDetails.specialty : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Nature of Business</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.natureOfBusiness ? customerDetails.natureOfBusiness : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Designation</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.designation ? customerDetails.designation : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Business License No.</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo ? customerDetails.profileInfo.businessLicenseNo : '-'}</label>
                                                                                                {customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.businessLicenseDateOfIssue && (<><br /><label className="text-muted" htmlFor="">{customerDetails.profileInfo.businessLicenseDateOfIssue}</label></>)}
                                                                                                {customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.businessLicensePlaceOfIssue && (<><br /><label className="text-muted" htmlFor="">{customerDetails.profileInfo.businessLicensePlaceOfIssue}</label></>)}
                                                                                                {getBusinessLicenseFileURL() ? (<><br /><div className="mt-1">
                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                </div></>) : <><br /><label className="mt-2"><i>[No file]</i></label></>}
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {/* <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Pharma Business License No.</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.profileInfo ? customerDetails.profileInfo.pharmaBusinessLicenseNo : '-'}</label>
                                                                                                {customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.pharmaBusinessLicenseDateOfIssue && (<><br /><label className="text-muted" htmlFor="">{customerDetails.profileInfo.pharmaBusinessLicenseDateOfIssue}</label></>)}
                                                                                                {customerDetails && customerDetails.profileInfo && customerDetails.profileInfo.pharmaBusinessLicensePlaceOfIssue && (<><br /><label className="text-muted" htmlFor="">{customerDetails.profileInfo.pharmaBusinessLicensePlaceOfIssue}</label></>)}
                                                                                                {getPharmaBusinessLicenseFileURL() ? (<><br /><div className="mt-1">
                                                                                                    <span className='text-info fs-13' onClick={() => setDisplayFileURL(getPharmaBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                </div></>) : <><br /><label className="mt-1"><i>[No file]</i></label></>}
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">SSM Form 9 / Form B</label>
                                                                                            <div className="col-lg-7">
                                                                                                {getBusinessLicenseFileURL() ? (<><div className="mt-2">
                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                </div></>) : <><label className="mt-2"><i>[No file]</i></label></>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Annual Practicing Certificate (APC)</label>
                                                                                            <div className="col-lg-7">
                                                                                                {getAPCFileURL() ? (<><div className="mt-2">
                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getAPCFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                </div></>) : <><label className="mt-2"><i>[No file]</i></label></>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Poison A License</label>
                                                                                            <div className="col-lg-7">
                                                                                                {getPoisonALicenseFileURL() ? (<><div className="mt-2">
                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getPoisonALicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                </div></>) : <><label className="mt-2"><i>[No file]</i></label></>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-5 col-form-label">Verification</label>
                                                                                            <div className="col-lg-7">
                                                                                                <label className="col-form-label text-muted" >{getVerifyLabel()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                        {/* Shipping Address*/}
                                                                        {data.key === CustomerTabKeys.SHIPPING_ADDRESS ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Contact Name</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo ? customerDetails.shippingInfo.contactName : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Contact Number</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-primary" >{customerDetails && customerDetails.shippingInfo ? customerDetails.shippingInfo.contactNumber : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Address</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{getShippingAddress()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Address Line 2</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo.address2 ? customerDetails.shippingInfo.address2 : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="form-group row">
                                                                                            <label className="col-lg-3 col-form-label">City</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo.city ? customerDetails.shippingInfo.city : '-'}</label>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-3 col-form-label">State</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo.state ? customerDetails.shippingInfo.state : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-3 col-form-label">Postal Code</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo.postalCode ? customerDetails.shippingInfo.postalCode : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Country</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.shippingInfo ? customerDetails.shippingInfo.country : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                        {/* Billing Address */}
                                                                        {data.key === CustomerTabKeys.BILLING_ADDRESS ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Name</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.billingInfo ? customerDetails.billingInfo.name : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Tax ID</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-primary" >{customerDetails && customerDetails.billingInfo ? customerDetails.billingInfo.taxCode : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Address</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{getBillingAddress()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Address Line 2</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.billingInfo.address2 ? customerDetails.billingInfo.address2 : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">City</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.billingInfo.city ? customerDetails.billingInfo.city : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">State</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.billingInfo.state ? customerDetails.billingInfo.state : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-3 col-form-label">Postal Code</label>
                                                                                            <div className="col-lg-9">
                                                                                                <label className="col-form-label text-muted" >{customerDetails && customerDetails.billingInfo.postalCode ? customerDetails.billingInfo.postalCode : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                    </Tab.Pane>
                                                                ))}
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </Card.Body>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Card>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </div >
                {displayFileURL && (
                    <div className="dialog-blur">
                        <div className="dialog-content">
                            <div className="text-end">
                                <span className='me-5'>
                                    <a onClick={closeToggle} href={displayFileURL} target='blank' className='text-info'><i className="fa fa-download"></i>Download File</a>
                                </span>
                                <span onClick={closeToggle} className='text-black'><i className="fa fa-times"></i> Close</span>
                            </div>
                            <hr />
                            <img src={displayFileURL} alt="" />
                        </div>
                    </div>
                )}
            </Offcanvas >
        </>
    );
});

export default VendorCustomerDetails;