export class BaseModel {
    constructor() {
        this.id = '';
        this.createdAt = null;
        this.updatedAt = null;
    }

    /**
     * Function filter properties.
     * @param val
     * @param filters
     */
    static filters(val, filters = []) {
        if (val != null && filters && filters.length > 0) {
            filters.forEach(field => {
                val[field] = undefined;
            });
        }
    }
}