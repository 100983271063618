import React, { useState } from "react";
import { RefIdModel } from "../../models/unified-customer.model";

const RenderRefIdOnlyView = ({ index, vendor, data }) => {
  const [refIdModel, setRefIdModel] = useState(data ? data : new RefIdModel());

  return (
    <>
      <div className="col-xl-6">
        <div className="card">
          <div className="card-header p-2 m-2">
            <label className="fs-7 text-primary">Ref. ID {index + 1}</label>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group mb-1 row">
                <label className="col-lg-6 col-form-label">Reference ID</label>
                <div className="col-lg-6">
                  <label className="col-form-label text-muted" >{refIdModel.refId}</label>
                </div>
              </div>
              <div className="form-group mb-1 row">
                <label className="col-lg-6 col-form-label">Vendor</label>
                <div className="col-lg-6">
                  <label className="col-form-label text-muted" >{vendor && vendor.companyName ? vendor.companyName : 'N/A'}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderRefIdOnlyView;
