export const TextKey = {
    data_invalid: 'Data is invalid.',
    failing_sending_data_to_vietpom: 'Failing sending data to POMacy system.',
    requied_fields: 'Please fill out all required fields.',
    not_contain_spaces: 'Field cannot contain spaces.',
    not_found: 'Not found.',
    update_successful: 'Update successful.',
    save_successful: 'Save successful.',
    delete_failed: 'Delete failed.',
    account_created_successfully: 'Account has been created successfully.',
    account_updated_successfully: 'Account has been updated successfully.',
    profile_updated_successfully: 'Profile has been updated successfully.',
    provide_business_license_certificate: 'Please provide the business license certificate file.',
    provide_apc_license_certificate: 'Please provide the APC certificate file.',
    provide_poison_a_license_certificate: 'Please provide the poison A license certificate file.',
    vendor_created_successfully: 'Vendor has been created successfully.',
    vendor_updated_successfully: 'Vendor has been updated successfully.',
    customer_created_successfully: 'Customer has been created successfully.',
    customer_updated_successfully: 'Customer has been updated successfully.',
    request_to_verify_customer_info: 'There is a request to verify customer information.',
    file_is_empty: 'File is empty, please select another file.',
    missing_vendor_id: 'Missing Vendor ID, please choose a vendor.',
    missing_vendor_id_contact_admin: 'Missing Vendor ID, please contact administrator.',
    import_successful: 'Import data successful.',
    phone_number_invalid: 'The phone number format is invalid.',
    email_invalid: 'The email address is invalid.',
    character_security_hint: '****************',
    allow_api_access_label: 'Allow API access',
    tap_to_display_api_key_label: '(Tap to display the api key)',
    generate_new_api_key_label: 'Generate new API Key',
    auth_basic_type_hint: 'Authorization type: Basic Auth',
    can_not_export_to_file: "Error! Can not export customers to file.",
    coming_soon: "Coming soon."
};

export const TextApp = {
    // Will be change in the future
    getText(key, lang = 'en') {
        return key;
    }
};
