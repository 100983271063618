import CustomersService from '../../services/CustomersService';

export const CustomersActionType = {
    GET_CUSTOMERS_ACTION: '[customers action] get customers',
    APPROVE_CUSTOMER_ACTION: '[customers action] approve customer',
    GET_CUSTOMER_DETAILS_ACTION: '[customers action] get customer details',
    DELETE_CUSTOMER_ACTION: '[customers action] delete customer'
}

export function deletePostAction(postId, history) {
    // return (dispatch, getState) => {
    //     deletePost(postId).then((response) => {
    //         dispatch(confirmedDeletePostAction(postId));
    //         history.push('/postpage');
    //     });
    // };
}

// export function confirmedDeletePostAction(postId) {
//     return {
//         type: CONFIRMED_DELETE_POST_ACTION,
//         payload: postId,
//     };
// }

// export function createPostAction(postData, history) {

//     return (dispatch, getState) => {
//         createPost(postData).then((response) => {
//             const singlePost = {
//                 ...postData,
//                 id: response.data.name,
//             };
//             dispatch(confirmedCreatePostAction(singlePost));
//             history.push('/postpage');
//         });
//     };
// }

export function getCustomersAction(query, navigate) {
    return (dispatch, getState) => {
        CustomersService.getCustomers(query).then((response) => {
            dispatch(responseGetCustomersAction(response));
        });
    };
}

// export function confirmedCreatePostAction(singlePost) {

//     return {
//         type: CONFIRMED_CREATE_POST_ACTION,
//         payload: singlePost,
//     };
// }

export function responseGetCustomersAction(customers) {
    return {
        type: CustomersActionType.GET_CUSTOMERS_ACTION,
        payload: customers,
    };
}

// export function confirmedUpdatePostAction(post) {

//     return {
//         type: CONFIRMED_EDIT_POST_ACTION,
//         payload: post,
//     };
// }

export function updatePostAction(post, history) {
    //     return (dispatch, getState) => {
    //         updatePost(post, post.id).then((reponse) => {
    //             dispatch(confirmedUpdatePostAction(post));
    //             history.push('/postpage');
    //         });

    //     };
}
