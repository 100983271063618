import { useEffect, useRef } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import CardWidget from './elements/CardWidget';
// import LatestImportedCustomers from './elements/LatestImportedCustomers';
// import ReportService from '../../../services/ReportService';
// import { showToastError } from '../../../helper/helper';

const Home = () => {
	const initialized = useRef(false);
	// const [loading, setLoading] = useState(false);
	// const [dataReport, setDataReport] = useState();

	useEffect(() => {
		if (initialized.current)
			return;

		initialized.current = true;
		// loadData();
	}, []);

	const loadData = async () => {
		// try {
		// 	setLoading(true);
		// 	await ReportService.getDashboardSummary()
		// 		.then((response) => {
		// 			setLoading(false);

		// 			if (response) {
		// 				setDataReport(response);
		// 			} else {
		// 				if (response && response.message) {
		// 					showToastError(response.message);
		// 				}
		// 			}
		// 		});
		// } catch (error) {
		// 	setLoading(false);

		// 	if (error && error.message) {
		// 		showToastError(error.message);
		// 	}
		// }
	};

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12">
						<div className="row">
							<CardWidget />
						</div>
					</div>
					{/* <div className="col-xl-12">
						<LatestImportedCustomers />
					</div> */}
				</div>
			</div>
		</>
	)
}

export default Home;