import { AuthActionType } from '../actions/AuthActions';

const initialState = {
    auth: {
        userId: '',
        accessToken: '',
        expiresIn: ''
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action == null || action.type == null || action.type === '')
        return state;

    const authEmpty = {
        userId: '',
        accessToken: '',
        expiresIn: ''
    };
    const resultReducer = {
        ...state,
        auth: action.payload ? action.payload : null,
        errorMessage: '',
        successMessage: '',
        showLoading: false,
    };

    switch (action.type) {
        case AuthActionType.SIGNUP_CONFIRMED_ACTION:
            resultReducer.successMessage = 'Signup Successfully Completed';
            break;

        case AuthActionType.LOGIN_CONFIRMED_ACTION:
            resultReducer.successMessage = 'Login Successfully Completed';
            break;

        case AuthActionType.LOGOUT_ACTION:
            resultReducer.auth = authEmpty;
            break;

        case AuthActionType.SIGNUP_FAILED_ACTION:
        case AuthActionType.LOGIN_FAILED_ACTION:
            resultReducer.auth = authEmpty;
            resultReducer.errorMessage = action.payload;
            break;

        case AuthActionType.LOADING_TOGGLE_ACTION:
            resultReducer.showLoading = action.payload;
            break;

        default:
            return state;
    }

    return resultReducer;
}
