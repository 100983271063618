/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import { IMAGES } from '../../constant/theme';
import AccountForm from './AccountForm';
import { showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import { AdminAccountModel, CreateListAccountsRequest } from '../../models/admin-info-model';
import UsersService from '../../../services/UsersService';
import { Roles, UserStatus } from '../../constant/AppConstant';
import { TextApp, TextKey } from '../../../assets/text-app';

const AddUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false);
    // Form
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [accountSelected, setAccountSelected] = useState(0);
    const [vendors, setVendors] = useState([]);

    const generateKey = (pre) => {
        return pre ? `${pre}_${new Date().getTime()}` : new Date().getTime();
    }

    const onHandleDeleteAccount = (index) => {
        if (adminAccounts.length > index) {
            adminAccounts.splice(index, 1);
            setAccountSelected(0);
            setAdminAccounts([...adminAccounts]);
        }
    };

    const onAddNewAdminAccount = () => {
        const newAdmin = new AdminAccountModel(Roles.ADMIN);
        newAdmin['id'] = generateKey();

        setAdminAccounts([...adminAccounts, newAdmin]);
    };

    const onUpdateAdminAccount = (index, data) => {
        if (adminAccounts.length > index) {
            adminAccounts[index] = data;

            setAdminAccounts([...adminAccounts]);
        }
    };

    const cleanAllAdminAccount = () => {
        const newAdmin = new AdminAccountModel(Roles.ADMIN);
        newAdmin['id'] = generateKey();

        setAdminAccounts([...[], newAdmin]);
        setAccountSelected(0);
    };

    // Load all vendors available
    const findVendors = async () => {
        try {
            const allVendors = await VendorsService.findVendors({}, false, true);
            const vendorsAvailable = allVendors && allVendors.length > 0 ? allVendors.filter((v) => { return v.allowCreateAccount && v.status === UserStatus.ACTIVE }) : [];

            setVendors(vendorsAvailable);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Get all vendors available.
        findVendors();
        // Default account created.
        onAddNewAdminAccount();
        // Default account selected
        if (adminAccounts.length > 0) {
            setAccountSelected(0);
        }
    }, [navigate, location]);

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        let requestModel = new CreateListAccountsRequest();
        // Admin Accounts
        requestModel.adminAccounts = adminAccounts;

        // Send data to create new account
        requestCreateNewAccount(JSON.stringify(requestModel));
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        let validateAdminAcc = false;

        // Admin accounts
        if (adminAccounts.length > 0) {
            for (let i = 0; i < adminAccounts.length; i++) {
                let errorAdminAcc = new AdminAccountModel();
                validateAdminAcc = AdminAccountModel.validate(adminAccounts[i], errorAdminAcc);

                if (!validateAdminAcc) {
                    // showToastWarning(`Missing required fields`);
                    validateAdminAcc = false;
                    break;
                }
            }
        }

        return validateAdminAcc;
    }

    /**
    * Request create new account
    * @param {} data 
    */
    const requestCreateNewAccount = async (data) => {
        try {
            setLoading(true);

            // Create new account
            await UsersService.createListAccount(data)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.account_created_successfully));
                        resetForm();
                        // navigate(-1);
                        navigate('/accounts', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        setAdminAccounts([]);
    };

    return (
        <>
            <MainPagetitle mainTitle="Admin Management" pageTitle="Profile" parentTitle="Home" />
            <div className="container-fluid">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Add New Account</h4>
                                {/* Add more account */}
                                <span>
                                    {adminAccounts && adminAccounts.length > 1 && <>
                                        <button className="btn btn-secondary light btn-sm ms-2" onClick={(e) => {
                                            e.preventDefault();
                                            cleanAllAdminAccount();
                                        }} >{`x Clear All`}</button>
                                    </>}
                                    <button className="btn btn-primary light btn-sm ms-2" onClick={(e) => {
                                        e.preventDefault();
                                        onAddNewAdminAccount();
                                    }} >{`+ Add Account`}</button>
                                </span>
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className='row'>
                                                                {adminAccounts.map((acc, i) => {
                                                                    return <div key={i} className='col-sm-1 p-2'>
                                                                        {accountSelected !== i && <><img key={i} src={IMAGES.IconDeleteX} className='icon-on-tab me-2' alt='' onClick={() => onHandleDeleteAccount(i)} /></>}
                                                                        <span onClick={() => setAccountSelected(i)} className={accountSelected === i ? 'text-selected' : ''}>{`Admin ${i + 1}`}</span>
                                                                    </div>
                                                                })}
                                                            </div>
                                                            <hr />
                                                            <div className="col-xl-12">
                                                                <span>
                                                                    {adminAccounts.map((acc, i) => {
                                                                        return accountSelected === i ? <AccountForm key={acc.id} index={i} onUpdate={onUpdateAdminAccount} data={acc} vendors={vendors} /> : '';
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Create Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            </div >
        </>
    )
}

export default AddUser;