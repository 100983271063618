import React, { useState } from "react";
import { UserStatusList } from "../../constant/AppConstant";
import { showToastWarning } from "../../../helper/helper";
import { AdminAccountModel } from "../../models/admin-info-model";
import { TextApp, TextKey } from "../../../assets/text-app";

const AdminAccount = ({ index, data, onDelete, onUpdate }) => {
  let errorObj = new AdminAccountModel();
  const [accountModel, setAccountModel] = useState(data ? data : new AdminAccountModel());
  const [errors, setErrors] = useState(errorObj);
  const [listUserStatus, setListUserStatus] = useState(UserStatusList);

  const handleInputProfile = (field, value) => {
    accountModel[field] = value;
    setAccountModel(accountModel);
    // Validate form
    AdminAccountModel.validate(accountModel, errorObj);
    setErrors({ ...errorObj });

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleInputProfileNoSpace = (field, value) => {
    validateNoSpace(value);
    accountModel[field] = value.replace(/\s+/g, "");
    setAccountModel(accountModel);
    // Validate form
    AdminAccountModel.validate(accountModel, errorObj);
    setErrors({ ...errorObj });

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const handleChooseUserStatus = (value) => {
    accountModel.status = value;
    setAccountModel(accountModel);
    setListUserStatus([...UserStatusList]);

    if (onUpdate) {
      onUpdate(index, accountModel);
    }
  }

  const validateNoSpace = (fieldValue) => {
    const tmpStr = fieldValue.split(' ');

    if (tmpStr && tmpStr.length > 1) {
      showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
    }
  }

  const isDisabledField = () => {
    if (accountModel && accountModel.vendorId != null && accountModel.vendorId !== '') {
      return true;
    }

    return false;
  }

  const isNewAccount = () => {
    if (accountModel == null || accountModel.vendorId == null || accountModel.vendorId === '') {
      return true;
    }

    return false;
  }

  return (
    <>
      <div className="col-xl-6">
        <div className="card">
          <div className="card-header">
            <label className="fs-6 text-primary">Admin {index + 1} {isNewAccount() && <span className="text-danger fs-12">(New)</span>}</label>
            <div className="d-flex align-items-center">
              <button className="btn btn-sm fs-12" onClick={onDelete}>x Delete</button>
            </div>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <div className="row">
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-name-${index}`}>Name<span className="text-danger">*</span></label>
                  <div className="col-lg-8">
                    <input type="text" className={`form-control ${isDisabledField() ? 'bg-disabled' : ''}`} id={`val-name-${index}`} name={`val-name-${index}`} value={accountModel.name} placeholder="" onChange={(e) => handleInputProfile('name', e.target.value)} disabled={isDisabledField()} />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-email-${index}`}>Email<span className="text-danger">*</span></label>
                  <div className="col-lg-8">
                    <input type="text" className={`form-control ${isDisabledField() ? 'bg-disabled' : ''}`} id={`val-email-${index}`} name={`val-email-${index}`} value={accountModel.email} onChange={(e) => handleInputProfileNoSpace('email', e.target.value)} placeholder="" disabled={isDisabledField()} />
                    {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                  </div>
                </div>
                {!isDisabledField() && <>
                  <div className="form-group mb-3 row">
                    <label className="col-lg-4 col-form-label" htmlFor={`val-password-${index}`}>Password<span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <input type="password" className="form-control" id={`val-password-${index}`} name={`val-password-${index}`} value={accountModel.password} onChange={(e) => handleInputProfile('password', e.target.value)} placeholder="" />
                      {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-lg-4 col-form-label" htmlFor={`val-confirm-password-${index}`}>Confirm Password<span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <input type="password" className="form-control" id={`val-confirm-password-${index}`} name={`val-confirm-password-${index}`} value={accountModel.confirmPassword} onChange={(e) => handleInputProfile('confirmPassword', e.target.value)} placeholder="" />
                      {errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
                    </div>
                  </div></>}
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-contact-${index}`}>Contact</label>
                  <div className="col-lg-8">
                    <input type="tel" className={`form-control ${isDisabledField() ? 'bg-disabled' : ''}`} id={`val-contact-${index}`} name={`val-contact-${index}`} value={accountModel.phoneNumber} onChange={(e) => handleInputProfileNoSpace('phoneNumber', e.target.value)} placeholder="" disabled={isDisabledField()} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-status-${index}`}>Status</label>
                  <div className="col-lg-8">
                    <select className={`form-control ${isDisabledField() ? 'bg-disabled' : ''}`} id={`val-status-${index}`} name={`val-status-${index}`} value={accountModel.status} onChange={(e) => handleChooseUserStatus(e.target.value)} disabled={isDisabledField()}>
                      {listUserStatus.length > 0 && (
                        (listUserStatus.map((v, i) => {
                          return (<option value={v.key} key={i}>{v.title}</option>);
                        }))
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAccount;
