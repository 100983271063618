/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Dropdown } from 'react-bootstrap';
import {
    ActionOnRecord, ConfigStatus, ConfigStatusList, ConfigTypes,
    MaxItemsPerPage, OptionMenuConfig, QueryParamsKey
} from '../../constant/AppConstant';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess } from '../../../helper/helper';
import PopupConfirmation from '../../../helper/popup-confirmation';
import SettingsService from '../../../services/SettingsService';

const Settings = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [menuOptions, setMenuOptions] = useState(OptionMenuConfig);
    const [configs, setConfigs] = useState([]);
    const deleteConfirmation = useRef();
    const [loading, setLoading] = useState(false);
    const [sortBySelected, setSortBySelected] = useState();
    const [filterSelected, setFilterSelected] = useState();
    const [keyword, setKeyword] = useState('');
    // Paging
    const [currentPage, setCurrentPage] = useState(1);
    const [paging, setPaging] = useState(null);

    let query = {};

    const renderPageIndex = (totalPage) => {
        const numPage = [...Array(totalPage + 1).keys()].slice(1)

        return numPage.map((n, i) => (
            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                onClick={() => changeCPage(n)}
            >
                {n}
            </Link>
        ));
    };

    // const sortByAction = (item) => {
    //     setSortBySelected(item);
    //     findAllConfiguration(keyword, filterSelected, item);
    // };

    // const filterAction = (item) => {
    //     setFilterSelected(item);
    //     findAllConfiguration(keyword, item, sortBySelected);
    // };

    function prePage() {
        if (currentPage !== 1) {
            query[QueryParamsKey.PAGE] = currentPage - 1;
            setCurrentPage(currentPage - 1);
            findAllConfiguration(keyword, filterSelected, sortBySelected);
        }
    }

    function changeCPage(id) {
        query[QueryParamsKey.PAGE] = id;
        setCurrentPage(id);
        findAllConfiguration(keyword, filterSelected, sortBySelected);
    }

    function nextPage() {
        if (paging && paging.pageCount && currentPage !== paging.pageCount) {
            query[QueryParamsKey.PAGE] = currentPage + 1;
            setCurrentPage(currentPage + 1);
            findAllConfiguration(keyword, filterSelected, sortBySelected);
        }
    }

    const onSearchByKey = (e) => {
        if (e.key === 'Enter') {
            findAllConfiguration(keyword, filterSelected, sortBySelected);
        }
    }

    // Load all configuration
    const findAllConfiguration = async (keyword, filter, sortBy) => {
        try {
            setLoading(true);
            query[QueryParamsKey.TAKE] = MaxItemsPerPage;
            query[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
            query[QueryParamsKey.FILTER_BY] = filter ? filter.key : '';
            query[QueryParamsKey.SORT_BY] = sortBy ? sortBy.key : '';

            SettingsService.findConfiguration(query)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response.paging) {
                            setPaging(response.paging);
                        }

                        setConfigs(response.data);
                    } else {
                        setConfigs([]);
                    }
                })
                .catch(err => {
                    setConfigs([]);
                    setLoading(false);
                });
        } catch (err) {
            setConfigs([]);
            setLoading(false);
        }
    };

    const getStatusLabel = (item) => {
        let status;

        if (item && item.status) {
            for (let i = 0; i < ConfigStatusList.length; i++) {
                if (item.status === ConfigStatusList[i].key) {
                    status = ConfigStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === ConfigStatus.ACTIVE ? 'available' :
                    status.key === ConfigStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    useEffect(() => {
        // Find all users
        findAllConfiguration();
    }, []);

    /**
     * Dialog confirm delete
     * @param {*} item 
     */
    const showDialogDelete = (item) => {
        const title = `Delete Configuration: ${item.title}`;
        const message = `Are you sure you want to delete this configuration?`;

        deleteConfirmation.current.showModal(item, { title: title, message: message });
    }

    const chooseOptionStatus = async (config, status) => {
        try {
            if (status === ActionOnRecord.DELETE) {
                showDialogDelete(config);
            } else {
                await SettingsService.updateConfigurationStatus(config.id, status)
                    .then((response) => {
                        if (response) {
                            // Reload data
                            findAllConfiguration(keyword, filterSelected, sortBySelected);

                            if (response.message) {
                                showToastSuccess(response.message);
                            }
                        }
                    });
            }
        } catch (error) {
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle delete configuration
     * @param {*} configuration
     */
    const handleDeleteConfiguration = async (config) => {
        if (config == null || config.id == null || config.id === '')
            return false;

        await SettingsService.deteteConfigurationById(config.id)
            .then((response) => {
                if (response) {
                    // Reload data
                    findAllConfiguration(keyword, filterSelected, sortBySelected);

                    if (response.message) {
                        showToastSuccess(response.message);
                    }
                }
            });
    }

    return (
        <>
            <MainPagetitle mainTitle="Settings Management" pageTitle={'List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <Link to={"/add-config"} className="btn btn-primary btn-sm">+ Add New Configuration</Link>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    {/* <th>Keyword</th> */}
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Description</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {configs && configs.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{index + 1}</span></td>
                                                        <td><span className='text-black auto-break-word'>{item.title}</span></td>
                                                        <td><span className="text-info">{ConfigTypes.GetName(item.type)}<br />({item.type})</span></td>
                                                        <td><span>{getStatusLabel(item)}</span></td>
                                                        <td><span>{item.description ? item.description : '-'}</span></td>
                                                        <td>
                                                            <Link to={{
                                                                pathname: "/edit-config",
                                                                search: `?${QueryParamsKey.ID}=${item.id}`,
                                                                state: { fromDashboard: true }
                                                            }}><img src={IMAGES.IconEdit} className="avatar avatar-sm mx-2" alt="" /></Link>
                                                            <Dropdown className="dropdown custom-dropdown mb-0">
                                                                <Dropdown.Toggle
                                                                    className="btn sharp btn-primary tp-btn i-false"
                                                                    data-toggle="dropdown">{SVGICON.MenuDot}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                    {menuOptions.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        chooseOptionStatus(item, st.key);
                                                                    }}>{st.title}</Dropdown.Item>))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {configs && configs.length > 0 ? <>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {configs ? configs.length : 0} of {paging && paging.total ? paging.total : 0} entries
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                    id="example2_paginate">
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={prePage}>
                                                        <i className="fa-solid fa-angle-left" />
                                                    </Link>
                                                    <span>{paging && paging.pageCount && renderPageIndex(paging.pageCount)}</span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={nextPage}>
                                                        <i className="fa-solid fa-angle-right" />
                                                    </Link>
                                                </div>
                                            </div></> : <><label className='m-4'>No data</label></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            <PopupConfirmation ref={deleteConfirmation} onConfirm={handleDeleteConfiguration} />
        </>
    );
};

export default Settings;