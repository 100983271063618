import React, { lazy, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Routes, Route, Outlet, useNavigate, useLocation } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Components
import Home from "./components/dashboard/Home";
import Vendors from "./components/vendors/Vendors";
import EditVendor from "./components/vendors/EditVendor";
import Customers from './components/customers/Customers';
import EditCustomer from "./components/customers/EditCustomer";
import Users from './components/accounts/Users';
import MyProfile from "./components/accounts/MyProfile";
import AddUser from './components/accounts/AddUser';
import EditUser from './components/accounts/EditUser';
import Settings from './components/settings/Settings';
import AddConfig from './components/settings/AddConfig';
import EditConfig from './components/settings/EditConfig';

/// Pages
// import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import LogoutPage from "./layouts/nav/Logout";
import { checkAutoLogin, isRoleAdmin } from "../services/AuthService";
import AddCustomer from "./components/customers/AddCustomer";
import VendorCustomers from "./components/vendors/VendorCustomers";
import AddVendorCustomer from "./components/vendors/AddVendorCustomer";
import EditVendorCustomer from "./components/vendors/EditVendorCustomer";
// import AddVendor from "./components/vendors/AddVendor";
import ImportVendorCustomers from "./components/vendors/ImportVendorCustomers";
import ImportCustomers from "./components/customers/ImportCustomers";
import UpdateCustomers from "./components/customers/UpdateCustomers";
// Page lazy load
const ViewCustomerByLink = lazy(() => import('./components/customers/ViewCustomerByLink'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./pages/Login')), 500);
  });
});

export const routesNoneAuth = [
  { url: "login", component: <Login /> },
  { url: "forgot-password", component: <ForgotPassword /> },
  { url: "view-customer", component: <ViewCustomerByLink /> }
];

const routesBase = [
  // Dashboard
  { url: "/", component: <Home /> },
  { url: "dashboard", component: <Home /> },
  // { url: "edit-profile", component: <EditProfile /> },
  { url: "my-profile", component: <MyProfile /> },
];

const routesAuthVendorOnly = [
  ...routesBase,
  { url: "vendor-customers", component: <VendorCustomers /> },
  { url: "edit-vendor-customer", component: <EditVendorCustomer /> },
  { url: "add-vendor-customer", component: <AddVendorCustomer /> },
  { url: "import-vendor-customers", component: <ImportVendorCustomers /> }
];

// For UCD admin
const routesAuth = [
  ...routesBase,
  { url: "vendors", component: <Vendors /> },
  // { url: "add-vendor", component: <AddVendor /> },
  { url: "edit-vendor", component: <EditVendor /> },
  { url: "customers", component: <Customers /> },
  { url: "import-customers", component: <ImportCustomers /> },
  { url: "update-customers", component: <UpdateCustomers /> },
  { url: "edit-customer", component: <EditCustomer /> },
  { url: "add-customer", component: <AddCustomer /> },
  { url: "accounts", component: <Users /> },
  { url: "add-account", component: <AddUser /> },
  { url: "edit-account", component: <EditUser /> },
  { url: "add-config", component: <AddConfig /> },
  { url: "edit-config", component: <EditConfig /> },
  { url: "settings", component: <Settings /> }
];

const Router = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routesAvailable, setRoutesAvailable] = useState([]);
  const location = useLocation();
  const isAdmin = isRoleAdmin();

  function NotFound() {
    const allRoutes = isAdmin ? routesAuth : routesAuthVendorOnly;
    const url = allRoutes.map((route) => route.url);
    const urlNoneAuth = routesNoneAuth.map((route) => route.url);
    let path = window.location.pathname;

    path = path.split('/');
    path = path[path.length - 1];

    if (url.indexOf(path) < 0) {
      if (props.isAuthenticated && urlNoneAuth.indexOf(path) >= 0) {
        return <LogoutPage />;
      } else {
        return <Error404 />
      }
    }
  }

  useEffect(() => {
    const allRoutes = isAdmin ? routesAuth : routesAuthVendorOnly;
    const url = allRoutes.map((route) => route.url);
    let path = location.pathname;
    setRoutesAvailable(allRoutes);

    path = path.split('/');
    path = path[path.length - 1];

    if (url.indexOf(path) >= 0) {
      checkAutoLogin(dispatch, navigate);
    }
  }, [dispatch, navigate, location]);

  return (
    <>
      <Routes>
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/page-error-403' element={<Error403 />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {routesAvailable.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Router);
// export default Router;
