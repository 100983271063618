import { ApiMethod, QueryParamsKey } from '../jsx/constant/AppConstant';
import Api, { ApiEndpoint, HttpMethod } from './ApiService';
const vsprintf = require('sprintf-js').vsprintf;

export default class SettingsService {
    /**
     * Find configuration
     * @param {*} queryParams 
     * @returns 
     */
    static async findConfiguration(queryParams = {}, isPaging = true, dataResponseOnly = false) {
        const url = `${ApiEndpoint}/${ApiMethod.Settings.ListConfiguration}`;

        queryParams[QueryParamsKey.IS_PAGING] = isPaging;

        return Api.invokeApi(url, HttpMethod.GET, { queryParams: queryParams, dataResponseOnly: dataResponseOnly })
            .then((response) => {
                return response;
            })
            .catch(err => {
                return null;
            });
    }


    /**
     * Get configuration details
     * @param id 
     * @returns 
     */
    static async getConfigurationDetails(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Settings.CongfigurationDetails}/${id}`;

        return Api.invokeApi(url, HttpMethod.GET, {})
            .then((response) => {
                return response;
            });
    }

    /**
     * Create new configuration
     * @param {*} data 
     * @returns 
     */
    static async createConfiguration(data) {
        const url = `${ApiEndpoint}/${ApiMethod.Settings.NewConfiguration}`;

        return Api.invokeApi(url, HttpMethod.POST, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update configuration
     * @param id 
     * @param {*} data 
     * @returns
     */
    static async updateConfiguration(id, data) {
        const url = `${ApiEndpoint}/${ApiMethod.Settings.UpdateConfiguration}/${id}`;

        return Api.invokeApi(url, HttpMethod.PUT, { payload: data, dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }

    /**
     * Update configuration status
     * @param {*} id 
     * @param {*} status 
     * @returns 
     */
    static async updateConfigurationStatus(id, status) {
        const url = vsprintf(`${ApiEndpoint}/${ApiMethod.Settings.UpdateConfigurationStatus}`, [id]);
        const data = {
            status: status
        };

        return Api.invokeApi(url, HttpMethod.PUT, { payload: JSON.stringify(data) })
            .then((response) => {
                return response;
            });
    }

    /**
     * Delete configuration by id
     * @param {*} id 
     * @returns 
     */
    static async deteteConfigurationById(id) {
        const url = `${ApiEndpoint}/${ApiMethod.Settings.DeleteById}/${id}`;

        return Api.invokeApi(url, HttpMethod.DELETE, { dataResponseOnly: false })
            .then((response) => {
                return response;
            });
    }
}
