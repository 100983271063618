import React from 'react';
import { Link } from 'react-router-dom';
import { SVGICON } from '../constant/theme';

const MainPagetitle = ({ pageTitle, parentTitle, mainTitle, onLogout }) => {
	return (
		<>
			<div className="page-titles">
				<ol className="breadcrumb">
					{onLogout ? <><button className="dropdown-item ai-icon mb-1 logout-btn" onClick={onLogout}>
						{SVGICON.Logout} {" "}
						<span className="ms-2">Logout</span>
					</button></> : ''}
					<li><h4 className="bc-title">{mainTitle}</h4></li>
					{parentTitle !== '' ? <li className="breadcrumb-item">
						<Link to={"/"}>
							{SVGICON.HomeSvg}
							{" "}{parentTitle}
						</Link>
					</li> : ''}
					{pageTitle !== '' ? <li className="breadcrumb-item active"><Link to={"#"}>{pageTitle}</Link></li> : ''}
				</ol>
			</div>
		</>
	);
};

export default MainPagetitle;