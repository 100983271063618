import { Suspense, useEffect } from 'react';

/// Components
import Router, { routesNoneAuth } from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./scss/main.css";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Make all routes none auth
  const makeRoutesNoneAuth = () => {
    return (<Routes>
      {routesNoneAuth.map((data, i) => (
        <Route key={i} exact path={`${data.url}`} element={data.component}
        />
      ))}
    </Routes>);
  };

  useEffect(() => {
    const urlNoneAuth = routesNoneAuth.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];

    if (urlNoneAuth.indexOf(path) < 0) {
      checkAutoLogin(dispatch, navigate);
    }
  }, [dispatch, navigate]);

  return (
    <>
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
      >
        {props.isAuthenticated ? (<Router />) : (makeRoutesNoneAuth())}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
