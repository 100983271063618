/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { CustomerInfoModel, FormDataRequest } from '../../models/customer-info-model';
import { showToast, showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import Preloader from '../../layouts/Preloader';
import { userDetails } from '../../../services/AuthService';
import ReportService from '../../../services/ReportService';
import CustomersService from '../../../services/CustomersService';
import { TextApp, TextKey } from '../../../assets/text-app';
import { ACCEPT_ONLY_CSV_FILE, ActionOnRecord, QueryParamsKey } from '../../constant/AppConstant';

const ImportVendorCustomers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialized = useRef(false);
    const userLogged = userDetails();
    const [loading, setLoading] = useState(false);

    let errorObj = new CustomerInfoModel();
    const [errors, setErrors] = useState(errorObj);
    const [customersFromFile, setCustomersFromFile] = useState();
    const [histories, setHistories] = useState([]);
    const [historySelected, setHistorySelected] = useState();
    const [resultImportCustomer, setResultImportCustomer] = useState();

    const handleChooseCustomersFile = (e) => {
        setCustomersFromFile(e.target.files[0]);
    }

    const getTemplateFileURL = () => {
        let fileURL = '';

        if (userLogged && userLogged.vendorId) {
            fileURL = ReportService.getURLTemplateFileVendorCustomers(userLogged.vendorId);
        }

        return fileURL;
    };

    const exportRecordsFailed = () => {
        let fileURL = '';

        if (historySelected && historySelected.id) {
            fileURL = ReportService.exportRecordsFailedToFile(historySelected.id);
        }

        return fileURL;
    };

    /**
     * Load history import customers by date
     */
    const loadHistoryImportCustomers = async () => {
        try {
            const query = {};
            query[QueryParamsKey.ACTION_TYPE] = ActionOnRecord.ON_CREATE;

            const data = await ReportService.getHistoryImportCustomers(query);
            setHistories(data);
        } catch (error) {
            console.warn(error);
        }
    };

    const getResultImportCustomer = async (id) => {
        try {
            let result = null;

            if (id) {
                result = await ReportService.getTotalRecordsImportFailed(id);
            }

            setResultImportCustomer(result);
        } catch (error) {
            console.warn(error);
        }
    };

    const chooseHistory = (id) => {
        let itemSelected = null;
        for (let i = 0; i < histories.length; i++) {
            if (id === histories[i].id) {
                itemSelected = histories[i];
                break;
            }
        }
        setHistorySelected(itemSelected);
        getResultImportCustomer(id);
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        loadHistoryImportCustomers();
    }, [navigate, location]);

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        if (customersFromFile == null) {
            showToastError(TextApp.getText(TextKey.file_is_empty));
            return;
        }

        if (userLogged.vendorId == null || userLogged.vendorId === '') {
            showToastError(TextApp.getText(TextKey.missing_vendor_id_contact_admin));
            return;
        }

        // Form data
        const data = new FormData();

        // Data submit
        data.append(FormDataRequest.FILE_KEY, customersFromFile);

        // Send file to UCD
        sendFileToUCD(data);
    };

    /**
    * Send file to server
    */
    const sendFileToUCD = async (data) => {
        try {
            setLoading(true);
            await CustomersService.importVendorCustomers(userLogged.vendorId, data)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response && response.message) {
                            showToastSuccess(response.message);
                        } else {
                            showToastSuccess(TextApp.getText(TextKey.import_successful));
                        }
                        resetForm();
                        loadHistoryImportCustomers();
                        // navigate(-1);
                        // navigate('/vendor-customers', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const getTotalRecordsImported = () => {
        const totalImported = historySelected && resultImportCustomer ? (historySelected.totalRecords - resultImportCustomer.totalSyncFailed - resultImportCustomer.totalSyncProcessing) : 0;

        return totalImported;
    };

    const getTotalRecordsProcessing = () => {
        const totalProcessing = historySelected && resultImportCustomer ? resultImportCustomer.totalSyncProcessing : 0;

        return totalProcessing;
    };

    const getTotalRecordsFailed = () => {
        const totalFailed = historySelected && resultImportCustomer ? resultImportCustomer.totalSyncFailed : 0;

        return totalFailed;
    };

    const resetForm = () => {
        setCustomersFromFile(null);
        setHistorySelected(null);
    };

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle="Import Customers" parentTitle="Home" />
            <div className="container-fluid">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Import Customers</h4>
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Card name="default-tab" className="dz-card">
                                            <Card.Body>
                                                <div className="form-group mb-3">
                                                    <label className="col-form-label" htmlFor="val-business-license-no">Vendor ID: <span className="text-danger">{userLogged && userLogged.vendorId ? userLogged.vendorId : '-'}</span></label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label" htmlFor="val-business-license-no">Upload customers from file (.csv, .xls)</label>
                                                    <input className="form-control" type="file" id="val-business-license-image" onChange={handleChooseCustomersFile} accept={ACCEPT_ONLY_CSV_FILE} required />
                                                    <label className="col-form-label"><a href={getTemplateFileURL()} target='self' className='text-info'><i className="fa fa-download"></i>Download template file</a></label>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {histories.length > 0 && <>
                                    <Row>
                                        <Col xl={12}>
                                            <label className="fs-6 text-primary">Import Results</label>
                                            <Card name="default-tab" className="dz-card">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <Card.Body>
                                                            <div className="form-group">
                                                                <label className="col-form-label" htmlFor="">Import History</label>
                                                                <select className="form-control" id="val-history" name="val-history" value={historySelected ? historySelected.id : ''} onChange={(e) => chooseHistory(e.target.value)} required>
                                                                    <option value="">Choose history record</option>
                                                                    {histories.length > 0 && (
                                                                        histories.map((his, i) => {
                                                                            return (<option value={his.id} key={i}>{his.importedDate}</option>);
                                                                        })
                                                                    )}
                                                                </select>
                                                                {getTotalRecordsProcessing() > 0 && <><span className="fs-13 text-danger" htmlFor="">(Processing . . . )</span></>}
                                                            </div>
                                                        </Card.Body>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <Card.Body>
                                                            <div className="form-group mb-2">
                                                                <label className="col-lg-4 col-form-label" htmlFor="">Total records:</label>
                                                                <label className="col-lg-8 col-form-label" htmlFor="">{historySelected ? historySelected.totalRecords : 0}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-lg-4 col-form-label" htmlFor="">Total records imported:</label>
                                                                <label className="col-lg-8 col-form-label" htmlFor="">{getTotalRecordsImported()}</label>
                                                            </div>
                                                        </Card.Body>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Card.Body>
                                                            <div className="form-group mb-2">
                                                                <label className="col-lg-4 col-form-label" htmlFor="">Total records processing:</label>
                                                                <label className="col-lg-8 text-danger" htmlFor="">{getTotalRecordsProcessing()}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-lg-4 col-form-label" htmlFor="">Total records failed:</label>
                                                                <label className="col-lg-8 text-danger" htmlFor="">{getTotalRecordsFailed()}</label>
                                                            </div>
                                                            {getTotalRecordsFailed() > 0 && exportRecordsFailed() && <>
                                                                <div className="form-group">
                                                                    <label className="col-lg-4 col-form-label"><a href={exportRecordsFailed()} target='self' className='text-info'><i className="fa fa-download"></i>Export records failed</a></label>
                                                                </div>
                                                            </>}
                                                        </Card.Body>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>}
                                <div className="form-group row my-4">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            </div >
        </>
    )
}

export default ImportVendorCustomers;