import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";
// import { ThemeContext } from "../../../context/ThemeContext";
import { navtoggle } from "../../../store/actions/ToggleActions";
// import logoNav from '../../../images/logo/logo-nav.png';
import logoNav from '../../../images/logo/ucd-logo.png';
import { getLocalData } from "../../../helper/helper";
import { LOCAL_STORAGE_KEY } from "../../../services/ApiService";
import AssetsService from "../../../services/AssetsService";
import { isRoleVendorAdmin } from "../../../services/AuthService";

const NavHader = () => {
  // const [toggle, setToggle] = useState(false);
  // const { navigationHader, openMenuToggle, background } = useContext(
  //   ThemeContext
  // );
  const dispatch = useDispatch();
  const sideMenu = useSelector(state => state.sideMenu);
  const [userInfo, setUserInfo] = useState(null);
  const isVendorAdmin = isRoleVendorAdmin();
  const [refreshPage, setRefreshPage] = useState(false);

  const fetchUserDetails = () => {
    const userDetailsStr = getLocalData(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);
    const userDetails = userDetailsStr ? JSON.parse(userDetailsStr) : null;

    if (userDetails) {
      setUserInfo(userDetails);
      getSignedLogoURL(userDetails.vendorInfo);
    }
  };

  const handleToogle = () => {
    dispatch(navtoggle());
  };

  const getLogoURL = () => {
    let fileURL = logoNav;

    if (isVendorAdmin && userInfo && userInfo.vendorInfo) {
      fileURL = userInfo.vendorInfo.logo && userInfo.vendorInfo.logo !== '' ? userInfo.vendorInfo.logo : logoNav;

      // if (userInfo.vendorInfo.keyFile && userInfo.vendorInfo.keyFile.logoKeyFile) {
      //   fileURL = AssetsService.getFileURL(userInfo.vendorInfo.keyFile.logoKeyFile);
      // }
    }

    return fileURL;
  };

  const getSignedLogoURL = async (vendorInfo) => {
    try {
      if (vendorInfo.keyFile && vendorInfo.keyFile.logoKeyFile) {
        const fileURL = AssetsService.getFileURL(vendorInfo.keyFile.logoKeyFile);

        vendorInfo.logo = await AssetsService.getSignedURL(fileURL);

        if (userInfo && userInfo.vendorInfo) {
          userInfo.vendorInfo.logo = vendorInfo.logo;
        }

        setRefreshPage(!refreshPage);
      }
    } catch (error) {
      // console.warn(error);
    }
  };

  useEffect(() => {
    if (isVendorAdmin) {
      fetchUserDetails();
    }
  }, []);

  return (
    <div className="nav-header bg-white">
      <Link to="/dashboard" className="brand-logo">
        {/* <img src={logoNav} alt="" /> */}
        {/* <img src={getLogoURL()} className={isVendorAdmin ? `avatar avatar-md` : ''} alt="" /> */}
        <img src={getLogoURL()} className={isVendorAdmin && userInfo && userInfo.vendorInfo.logo ? `avatar` : ''} alt="" />
      </Link>

      <div
        className="nav-control bg-light"
        onClick={() => {
          handleToogle()
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line bg-black"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
