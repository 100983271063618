import { LocationPriorityDefault } from "../jsx/constant/AppConstant";

/**
 * Filter unique object in list
 * @param {*} arr 
 * @param {*} uniqueKey 
 * @returns 
 */
export const arrayUnique = (arr, uniqueKey) => {
  const flagList = new Set()

  return arr.filter((item) => {
    if (!flagList.has(item[uniqueKey])) {
      flagList.add(item[uniqueKey])
      return true;
    }
    return false;
  });
};

/**
 * Filter all states
 * @param {*} data 
 * @returns 
 */
export const filterStates = (data) => {
  const finalStates = data ? arrayUnique(data, "CodeState") : [];
  // const statesSortByName = finalStates.sort((it1, it2) => it1.NameState.toLowerCase() > it2.NameState.toLowerCase() ? 1 : -1);

  return finalStates;
};

/**
 * Filter all cities
 * @param {*} data 
 * @returns 
 */
export const filterCities = (data) => {
  const finalCities = data ? arrayUnique(data, "CodeCity") : [];
  const citiesSortByName = finalCities.sort((it1, it2) => it1.NameCity.toLowerCase() > it2.NameCity.toLowerCase() ? 1 : -1);

  if (LocationPriorityDefault.ListAvailable().length > 0 && citiesSortByName.length > 0) {
    const citiesPriority = [];
    const citiesTemp = citiesSortByName.filter((loc) => String(loc.CodeCity) !== String(LocationPriorityDefault.VN_HCM.CodeCity) && String(loc.CodeCity) !== String(LocationPriorityDefault.VN_HN.CodeCity));

    citiesPriority.push(...LocationPriorityDefault.ListAvailable());
    citiesPriority.push(...citiesTemp);

    return citiesPriority;
  } else {
    return citiesSortByName;
  }
};

/**
 * Filter all district by city code
 * @param {*} data
 * @param {*} cityCode 
 * @returns 
 */
export const filterDistricts = (data, cityCode) => {
  const districts = data ? data.filter((loc) => String(loc.CodeCity) === String(cityCode)) : [];
  const finalDistricts = districts ? arrayUnique(districts, "CodeDistrict") : [];
  const districtsSortByName = finalDistricts.sort((it1, it2) => it1.NameDistrict.toLowerCase() > it2.NameDistrict.toLowerCase() ? 1 : -1);

  return sortDistrictWard(districtsSortByName, "NameDistrict");
};

/**
 * Filter all wards by district code
 * @param {*} data
 * @param {*} cityCode 
 * @returns 
 */
export const filterWards = (data, districtCode) => {
  const finalWards = data ? data.filter((loc) => String(loc.CodeDistrict) === String(districtCode)) : [];
  const wardsSortByName = finalWards.sort((it1, it2) => it1.NameWard.toLowerCase() > it2.NameWard.toLowerCase() ? 1 : -1);

  return sortDistrictWard(wardsSortByName, "NameWard");
};

export const findCountryByCode = (countries, code) => {
  let result = countries ? countries.filter((loc) => String(loc.code) === String(code)) : null;

  if (result && result.length > 0) {
    return result[0];
  }

  return null;
}

/**
 * Find state by code
 * @param {*} states 
 * @param {*} code 
 * @returns 
 */
export const findStateByCode = (states, code) => {
  let result = states ? states.filter((loc) => String(loc.CodeState) === String(code)) : null;

  if (result && result.length > 0) {
    return result[0];
  }

  return null;
}

/**
 * Find city by code
 * @param {*} cities 
 * @param {*} code 
 * @returns 
 */
export const findCityByCode = (cities, code) => {
  let result = cities ? cities.filter((loc) => String(loc.CodeCity) === String(code)) : null;

  if (result && result.length > 0) {
    return result[0];
  }

  return null;
}

/**
 * Find district by code
 * @param {*} districts 
 * @param {*} code 
 * @returns 
 */
export const findDistrictByCode = (districts, code) => {
  let result = districts ? districts.filter((loc) => String(loc.CodeDistrict) === String(code)) : null;

  if (result && result.length > 0) {
    return result[0];
  }

  return null;
}

/**
 * Find ward by code
 * @param {*} code 
 * @returns 
 */
export const findWardByCode = (wards, code) => {
  let result = wards ? wards.filter((loc) => String(loc.CodeWard) === String(code)) : null;

  if (result && result.length > 0) {
    return result[0];
  }

  return null;
}

export const formatDistrictWard = (name, prefix) => {
  if (!isNaN(name) && prefix) {
    return `${prefix} ${name}`;
  }

  return name;
}

/**
 * Sort districts / wards
 * @param {*} data 
 * @param {*} propName 
 * @returns 
 */
export const sortDistrictWard = (data, propName) => {
  // Custom list
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let tempItem = data[i];

      for (let j = i + 1; j < data.length; j++) {
        const itemStep1 = data[j];

        if (!isNaN(tempItem[propName]) && !isNaN(itemStep1[propName]) && Number(tempItem[propName]) > Number(itemStep1[propName])) {
          tempItem = itemStep1;
          data[j] = data[i];
          data[i] = tempItem;
        }
      }
    }
  }

  return data;
}
