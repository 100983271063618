/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import { ConfigStatus, ConfigStatusList, ConfigTypes } from '../../constant/AppConstant';
import { TextApp, TextKey } from '../../../assets/text-app';
import { ConfigInfoModel } from '../../models/config-info-model';
import SettingsService from '../../../services/SettingsService';

const AddConfig = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false);
    let errorObj = new ConfigInfoModel();
    const [configTypes, setConfigTypes] = useState([]);
    const [configInfo, setConfigInfo] = useState(new ConfigInfoModel());
    const [errors, setErrors] = useState(errorObj);
    const [refreshForm, setRefreshForm] = useState(false);

    const handleInputInfo = (field, value) => {
        configInfo[field] = value;

        setConfigInfo(configInfo);
        setRefreshForm(!refreshForm);
    }

    // const handleInputInfoNoSpace = (field, value) => {
    //     validateNoSpace(value);
    //     configInfo[field] = value.replace(/\s+/g, "");

    //     setConfigInfo(configInfo);
    //     setRefreshForm(!refreshForm);
    // }

    const handleChooseConfigType = (value) => {
        configInfo.type = value;

        setConfigInfo(configInfo);
        setRefreshForm(!refreshForm);
    }

    const handleChooseConfigStatus = (value) => {
        configInfo.status = value;

        setConfigInfo(configInfo);
        setRefreshForm(!refreshForm);
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Types
        const types = ConfigTypes.ListAvailable();
        setConfigTypes(types);
        // Default info.
        const configDefault = types.filter((item) => item.default);

        if (configDefault && configDefault.length > 0) {
            configInfo.title = configDefault[0].title;
            configInfo.type = configDefault[0].key;
            configInfo.status = ConfigStatus.ACTIVE;
        } else {
            configInfo.status = ConfigStatus.DISABLED;
        }

        setConfigInfo(configInfo);
        setRefreshForm(!refreshForm);
    }, [navigate, location]);

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        // Send data to create new configuration
        requestCreateNewConfiguration(configInfo);
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        // Validate form
        const resultValidate = ConfigInfoModel.validate(configInfo, errorObj);
        setErrors({ ...errorObj });

        return resultValidate;
    }

    /**
    * Request create configuration
    * @param {} data 
    */
    const requestCreateNewConfiguration = async (data) => {
        try {
            setLoading(true);

            await SettingsService.createConfiguration(JSON.stringify(data))
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.save_successful));
                        resetForm();
                        // navigate(-1);
                        navigate('/settings', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        setConfigInfo(new ConfigInfoModel());
    };

    return (
        <>
            <MainPagetitle mainTitle="Settings Management" pageTitle="Details" parentTitle="Home" />
            <div className="container-fluid">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Add New Configuration</h4>
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            <div className="form-validation">
                                                                <div className="row">
                                                                    <div className="col-xl-6">
                                                                        <div className="row">
                                                                            <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-title`}>Title<span className="text-danger">*</span></label>
                                                                                <div className="col-lg-8">
                                                                                    <input type="text" className="form-control" id={`val-title`} name={`val-title`} value={configInfo.title} placeholder="" onChange={(e) => handleInputInfo('title', e.target.value)} />
                                                                                    {errors.title && <div className="text-danger fs-12">{errors.title}</div>}
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-keyword`}>Keyword<span className="text-danger">*</span></label>
                                                                                <div className="col-lg-8">
                                                                                    <input type="text" className="form-control bg-disabled" id={`val-keyword`} name={`val-keyword`} value={configInfo.keyword} onChange={(e) => handleInputInfoNoSpace('keyword', e.target.value)} placeholder="Auto generate from systems" disabled />
                                                                                    {errors.keyword && <div className="text-danger fs-12">{errors.keyword}</div>}
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-value`}>Value<span className="text-danger">*</span></label>
                                                                                <div className="col-lg-8">
                                                                                    <input type="text" className="form-control" id={`val-value`} name={`val-value`} value={configInfo.value} onChange={(e) => handleInputInfo('value', e.target.value)} placeholder="" />
                                                                                    {errors.value && <div className="text-danger fs-12">{errors.value}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-description`}>Description</label>
                                                                                <div className="col-lg-8">
                                                                                    <textarea type="text" rows="5" className="form-control" id="val-description" name="val-description" value={configInfo.description} onChange={(e) => handleInputInfo('description', e.target.value)} placeholder="" ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6">
                                                                        <div className="row">
                                                                            <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-roles`}>Type<span className="text-danger">*</span></label>
                                                                                <div className="col-lg-8">
                                                                                    <select className="form-control" id={`val-roles`} name={`val-roles`} value={configInfo.type} onChange={(e) => handleChooseConfigType(e.target.value)}>
                                                                                        <option value="">Choose type</option>
                                                                                        {configTypes.map((v, i) => {
                                                                                            return (<option value={v.key} key={i}>{v.title}</option>);
                                                                                        })}
                                                                                    </select>
                                                                                    {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group mb-3 row">
                                                                                <label className="col-lg-4 col-form-label" htmlFor={`val-roles`}>Status<span className="text-danger">*</span></label>
                                                                                <div className="col-lg-8">
                                                                                    <select className="form-control" id={`val-roles`} name={`val-roles`} value={configInfo.status} onChange={(e) => handleChooseConfigStatus(e.target.value)}>
                                                                                        {ConfigStatusList.map((v, i) => {
                                                                                            return (<option value={v.key} key={i}>{v.title}</option>);
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            </div >
        </>
    )
}

export default AddConfig;