import { NAV_TOGGLE_ACTION } from '../actions/ToggleActions';

const initial = false;

function toggleMenu(state = initial, action) {
    if (action.type === NAV_TOGGLE_ACTION) {
        return !state
    }
    return state;
}

export { toggleMenu };