// import axios from 'axios';
// import swal from "sweetalert";
import { ApiMethod, Roles } from '../jsx/constant/AppConstant';
import {
    loginConfirmedAction, logoutAction,
} from '../store/actions/AuthActions';
import Api, { ApiEndpoint, HttpMethod, LOCAL_STORAGE_KEY } from './ApiService';

export function signUp(email, password) {
    const signUpURL = `${ApiEndpoint}/${ApiMethod.Auth.Register}`;
    const postData = { email, password };

    return Api.invokeApi(signUpURL, HttpMethod.POST, { payload: JSON.stringify(postData) })
        .then((resultAuth) => {
            if (resultAuth && resultAuth.accessToken) {
                return resultAuth;
            }

            return null;
        });
}

export function login(email, password) {
    const loginURL = `${ApiEndpoint}/${ApiMethod.Auth.Login}`;
    const postData = { email, password };

    return Api.invokeApi(loginURL, HttpMethod.POST, { payload: JSON.stringify(postData) })
        .then((resultAuth) => {
            if (resultAuth && resultAuth.accessToken) {
                return resultAuth;
            }

            return null;
        });
}

export function logout() {
    const logoutURL = `${ApiEndpoint}/${ApiMethod.Auth.Logout}`;

    return Api.invokeApi(logoutURL, HttpMethod.POST, {})
        .then((response) => {
            return response;
        });
}

export function saveTokenInLocalStorage(data) {
    const tokenInfo = {
        expireDate: data.expiresIn && Number(data.expiresIn) > 0 ? new Date(new Date().getTime() + Number(data.expiresIn) * 1000) : -1,
        accessToken: data.accessToken,
        userId: data.userId
    }

    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY, JSON.stringify(tokenInfo));
    localStorage.setItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY, data.user ? JSON.stringify(data.user) : '');
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(logoutAction(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
    const tokenDetails = tokenDetailsString != null && tokenDetailsString !== '' ? JSON.parse(tokenDetailsString) : null;

    if (tokenDetails == null || (tokenDetails && (tokenDetails.accessToken == null || tokenDetails.accessToken === ''))) {
        dispatch(logoutAction(navigate));
        return;
    }

    // Check expire token
    if (tokenDetails.expireDate) {
        let expireDate = new Date(tokenDetails.expireDate);
        let todaysDate = new Date();

        if (todaysDate > expireDate) {
            dispatch(logoutAction(navigate));
            return;
        }

        const timer = expireDate.getTime() - todaysDate.getTime();
        runLogoutTimer(dispatch, timer, navigate);
    }

    dispatch(loginConfirmedAction(tokenDetails));
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);

    if (tokenDetailsString) {
        return true;
    } else {
        return false;
    }
}

export function userDetails() {
    const userDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);

    if (userDetailsString) {
        return JSON.parse(userDetailsString);
    } else {
        return null;
    }
}

export function isRoleVendorAdmin() {
    const userDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);
    let userDetails = null;

    if (userDetailsString) {
        userDetails = JSON.parse(userDetailsString);
    }

    if (userDetails) {
        const userRoles = userDetails.roles ? userDetails.roles.split(',') : [];

        return userRoles.includes(Roles.VENDOR_ADMIN);
    } else {
        return false;
    }
}

export function isRoleAdmin() {
    const userDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);
    let userDetails = null;

    if (userDetailsString) {
        userDetails = JSON.parse(userDetailsString);
    }

    if (userDetails) {
        const userRoles = userDetails.roles ? userDetails.roles.split(',') : [];

        return userRoles.includes(Roles.SUPER_ADMIN) || userRoles.includes(Roles.ADMIN);
    } else {
        return false;
    }
}

export function isRoleSuperAdmin() {
    const userDetailsString = localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);
    let userDetails = null;

    if (userDetailsString) {
        userDetails = JSON.parse(userDetailsString);
    }

    if (userDetails) {
        const userRoles = userDetails.roles ? userDetails.roles.split(',') : [];

        return userRoles.includes(Roles.SUPER_ADMIN);
    } else {
        return false;
    }
}